import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import { Nullable } from '@common/interfaces';
import { getDay } from 'date-fns';
import css from './styles.module.scss';

interface IPropositionCalendar {
  dates: Date[];
  disabledDays: number[];
  maxDate?: Nullable<Date>;
  minDate?: Nullable<Date>;
  onChange: (dates: Date[]) => void;
}

const PropositionCalendar: FC<IPropositionCalendar> = ({ dates, disabledDays, maxDate, minDate, onChange }) => {
  const filterDate = (date: Date) => {
    const day = getDay(date);
    return !disabledDays.includes(day);
  };

  return (
    <DatePicker
      calendarClassName={css.calendars}
      autoComplete='off'
      calendarStartDay={1}
      filterDate={filterDate}
      inline
      maxDate={maxDate}
      minDate={minDate}
      monthsShown={2}
      onChange={onChange}
      selectedDates={dates}
      selectsMultiple
    />
  );
};

export default PropositionCalendar;
