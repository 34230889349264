import React, { FC, useState } from 'react';
import { useAuth } from '@unbooking/ui-auth';
import { useQuery } from 'react-query';
import { CMSNavigationResponse } from '@common/interfaces';
import { useFacilityContext, useGlobalContext, useRepository } from '@context';
import { Button } from '@components';
import { Link } from 'react-router-dom';
import { AppType, URLS } from '@common/constants';
import { HomeIcon, LockIcon, LogoWFPIcon, MenuIcon } from '@assets/svg';
import Navigation from '../Navigation';
import { useAppType } from '@common/hooks';
import NavigationMobile from '@components/NavigationMobile';
import css from './styles.module.scss';

const Header: FC = () => {
  const { cmsRepository } = useRepository();
  const { managedFacilities } = useFacilityContext();
  const { waffles, user } = useGlobalContext();
  const appType = useAppType();
  const { logout } = useAuth();

  const headerType = `${appType}-header`;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { data: header } = useQuery<CMSNavigationResponse>(['cms-navigation', headerType], () =>
    cmsRepository.getElement(headerType)
  );

  const closeMenu = () => setMobileMenuOpen(false);

  const logoUrl = header?.logo?.meta.download_url;

  let mainHeaderElement = null;
  let mobileMainHeaderElement = null;

  switch (appType) {
    case AppType.DIGITAL_OFFICE:
      mainHeaderElement = (
        <>
          <Link to={URLS.DO} className={css.logo}>
            {logoUrl ? <img src={logoUrl} alt='Digital Office' /> : null}
          </Link>
          <Link to={URLS.ROOT} className={css.button}>
            <HomeIcon />
            <span>
              Go to the <br />
              <b>Workshop Platform</b>
            </span>
          </Link>
        </>
      );
      mobileMainHeaderElement = (
        <Link to={URLS.ROOT} className={css.button} onClick={closeMenu}>
          <HomeIcon />
          <span>
            Go to the <br />
            <b>Workshop Platform</b>
          </span>
        </Link>
      );
      break;
    case AppType.CUSTOMER_AREA:
      mainHeaderElement = (
        <>
          <Link to={URLS.CUSTOMER_AREA} className={css.logo}>
            {logoUrl ? <img src={logoUrl} alt='Customer Area' /> : null}
          </Link>
          <Link to={URLS.ROOT} className={css.button}>
            <HomeIcon />
            <span>
              Go to the <br />
              <b>Workshop Platform</b>
            </span>
          </Link>
        </>
      );
      mobileMainHeaderElement = (
        <Link to={URLS.ROOT} className={css.button} onClick={closeMenu}>
          <HomeIcon />
          <span>
            Go to the <br />
            <b>Workshop Platform</b>
          </span>
        </Link>
      );
      break;
    case AppType.DEFAULT:
      mainHeaderElement = (
        <>
          <Link to={URLS.ROOT} className={css.logo}>
            {logoUrl ? <img src={logoUrl} alt='Maintenance Services' /> : null}
          </Link>
          <div className={css.buttons}>
            {managedFacilities.length > 0 ? (
              <Link to={URLS.DO} className={css.button}>
                <LockIcon />
                <span>
                  Digital
                  <br />
                  <b>Office</b>
                </span>
              </Link>
            ) : null}
            {waffles.flags.CUSTOMER_AREA.is_active && user.customer_area_location ? (
              <Link to={URLS.CUSTOMER_AREA} className={`${css.button} ${css.buttonCustomer}`}>
                <LockIcon />
                <span>
                  My maintenance
                  <br />
                  Requests
                </span>
              </Link>
            ) : null}
          </div>
        </>
      );
      mobileMainHeaderElement = (
        <>
          {managedFacilities.length > 0 ? (
            <Link to={URLS.DO} className={css.button} onClick={closeMenu}>
              <LockIcon />
              <span>
                Digital
                <br />
                <b>Office</b>
              </span>
            </Link>
          ) : null}
          {waffles.flags.CUSTOMER_AREA.is_active && user.customer_area_location ? (
            <Link to={URLS.CUSTOMER_AREA} className={css.button} onClick={closeMenu}>
              <LockIcon />
              <span>
                Customer
                <br />
                <b>Area</b>
              </span>
            </Link>
          ) : null}
        </>
      );
      break;
  }

  const userElement = (
    <div className={css.user}>
      <LogoWFPIcon className={css.logoWfp} />
      <div className={css.content}>
        <div className={css.name}>{`Welcome ${user.first_name} ${user.last_name}`}, {user.organisation?.short_name}</div>
        <div className={css.actions}>
          <Button className={css.logout} text='Log out' variant='text' onClick={logout} />
        </div>
      </div>
    </div>
  );

  const mobileUserElement = (
    <div className={css.user}>
      <div className={css.name}>{`Welcome, ${user.first_name} ${user.last_name}`}</div>
      <div>{user.organisation?.short_name}</div>
      <Button className={css.logout} text='Log out' variant='text' onClick={logout} />
    </div>
  );

  return (
    <div className={css.wrapper}>
      <header className={css.header}>
        <Button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} iconL={<MenuIcon />} className={css.menuButton} />
        {mainHeaderElement}
        {userElement}
      </header>
      <NavigationMobile navigation={header?.navigation || []} isActive={mobileMenuOpen} closeMenu={closeMenu}>
        <div className={css.mobileMenuInfo}>
          {mobileMainHeaderElement}
          {mobileUserElement}
        </div>
      </NavigationMobile>
      <Navigation navigation={header?.navigation || []} />
    </div>
  );
};

export default Header;
