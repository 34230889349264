export enum QueryParams {
  FACILITY_ID = 'facility_id',
  TAB = 'tab',
}

export enum LocalStorageKey {
  FACILITY_ID_MONOLITH = 'FACILITY_ID_MONOLITH',
}

export enum SessionStorageKey {
  BOOKING_LIST_PARAMS = 'bookingListParams',
  DOCUMENTS_LIST_PARAMS = 'documentsListParams',
  CUSTOMER_BOOKING_LIST_PARAMS = 'customerBookingListParams',
  CUSTOMER_DOCUMENTS_LIST_PARAMS = 'customerDocumentsListParams',
}

export enum JobcardStatus {
  WAITING_FOR_PARTS = 'Waiting for parts',
  WORK_IN_PROGRESS = 'Work in progress',
  CLOSED = 'Closed',
}

export type Nullable<T> = T | null;

export interface DataItem {
  label: string;
  value: string;
}

export interface ValueItem {
  label: string;
  value: Nullable<number>;
}

export interface ServerItem {
  display_name: string;
  value: string;
}

export interface ValueItemColorized extends ValueItem {
  backgroundColor?: string;
}

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface PaginatedResponse<Item> {
  count: number;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: Item[];
}

export interface DefaultListParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: string;
}

export interface InformedFieldState<Value> {
  error: string;
  showError: boolean;
  value: Value;
}

export interface DateRange {
  created_before?: string;
  created_after?: string;
}
