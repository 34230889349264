import { FC, useState } from 'react';
import { Button } from '@components';
import { DocDownloadIcon, DocUploadIcon, EditIcon, SuccessIcon, WrenchCarIcon } from '@assets/svg';
import {
  IAppointmentAcceptData,
  IAppointmentType,
  ICustomerBookingDetails,
  IBookingStatus,
  IDefects,
  ProFormaInvoice,
} from '@common/interfaces';
import { useRepository } from '@context';
import { getLocalDate } from '@common/utils';
import ProposeDatesModal from '../ProposeDatesModal';
import ApproveDefectsModal from '../ApproveDefectsModal';
import { format } from 'date-fns';
import { DEFAULT_USER_DATE_TIME_FORMAT } from '@common/constants';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import css from './styles.module.scss';
import UploadFileModal from '../UploadFileModal';
import SurveyModal from '../SurveyModal';
import SuccessModal from '../SuccessModal';

interface IActionsBlock {
  bookingDetails: ICustomerBookingDetails;
  refetch: () => void;
  refetchDefects: () => void;
  pfiData?: ProFormaInvoice;
  defects?: IDefects;
}

const ActionsBlock: FC<IActionsBlock> = ({ pfiData, defects, bookingDetails, refetchDefects, refetch }) => {
  const { customerRepository } = useRepository();

  const { state, appointments, uuid: bookingId, pfi, feedback_created, facility_work_time } = bookingDetails;

  const [showProposeDatesModal, setShowProposeDatesModal] = useState(false);
  const [showApproveDefectsModal, setShowApproveDefectsModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { mutate: acceptAppointment } = useMutation(
    (data: IAppointmentAcceptData) => customerRepository.acceptAppointment(checkInData?.uuid || '', data),
    {
      onSuccess: ({ accepted }: IAppointmentAcceptData) => {
        const text = accepted ? 'Date acceptance completed' : 'Proposed dates sent';
        toast.success(text);
        refetch();
      },
    }
  );

  const acceptDate = () => acceptAppointment({ proposed_check_in_dates: [], accepted: true });

  let content = null;

  const checkInData = appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_IN);

  const checkInAction =
    state === IBookingStatus.CHECK_IN_SCHEDULED && checkInData && !checkInData.proposed_check_in_dates;

  const defectsAction =
    state === IBookingStatus.INSPECTED && (defects?.known_issues || []).some(({ need_to_accept }) => need_to_accept);

  const pfiAction = state === IBookingStatus.PFI_SENT && pfiData;

  const surveyAction = state === IBookingStatus.INVOICE_PAID && !feedback_created;

  if (checkInAction) {
    const checkInDateToConfirm = getLocalDate(checkInData.from_to_datetime_range.from);
    content = (
      <div className={css.box}>
        Check-in date to confirm: {format(checkInDateToConfirm, DEFAULT_USER_DATE_TIME_FORMAT)}
        <div className={css.buttons}>
          <Button
            text='Propose new date'
            variant='geyser'
            iconR={<EditIcon />}
            onClick={() => setShowProposeDatesModal(true)}
          />
          <Button text='Accept check-in date' variant='forest' iconR={<SuccessIcon />} onClick={acceptDate} />
        </div>
      </div>
    );
  } else if (defectsAction) {
    content = (
      <div className={css.box}>
        New defects detected to be approved
        <div className={css.buttons}>
          <Button text='View new defects' iconR={<WrenchCarIcon />} onClick={() => setShowApproveDefectsModal(true)} />
        </div>
      </div>
    );
  } else if (pfiAction) {
    content = (
      <div className={css.box}>
        Upload signed PFI
        <div className={css.buttons}>
          <Button
            link={pfiData.pdf || ''}
            target='_blank'
            text='Download PFI'
            variant='transparent'
            iconR={<DocDownloadIcon />}
          />
          <Button text='Upload signed PFI' iconR={<DocUploadIcon />} onClick={() => setShowUploadFileModal(true)} />
        </div>
      </div>
    );
  } else if (surveyAction) {
    content = (
      <div className={css.box}>
        We value your feedback! Please take a moment to complete this quick survey to help us improve.
        <div className={css.buttons}>
          <Button onClick={() => setShowSurveyModal(true)} text='Share your feedback' />
        </div>
      </div>
    );
  }

  return (
    <>
      {content}
      {showProposeDatesModal && checkInData ? (
        <ProposeDatesModal
          workSchedule={facility_work_time}
          closeModal={() => setShowProposeDatesModal(false)}
          acceptAppointment={acceptAppointment}
        />
      ) : null}
      {showApproveDefectsModal && defects ? (
        <ApproveDefectsModal
          bookingId={bookingId}
          closeModal={() => setShowApproveDefectsModal(false)}
          defects={defects}
          refetchDefects={refetchDefects}
        />
      ) : null}
      {showUploadFileModal && pfi ? (
        <UploadFileModal refetch={refetch} pfiId={pfi} closeModal={() => setShowUploadFileModal(false)} />
      ) : null}
      {showSurveyModal ? (
        <SurveyModal
          refetch={refetch}
          bookingId={bookingId}
          closeModal={() => {
            setShowSurveyModal(false);
            setShowSuccessModal(true);
          }}
        />
      ) : null}
      {showSuccessModal ? <SuccessModal closeModal={() => setShowSuccessModal(false)} /> : null}
    </>
  );
};

export default ActionsBlock;
