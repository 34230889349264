import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import {
  ICustomerRepository,
  ICustomerBookingListParams,
  ICustomerBookingFiltersParams,
  ICommentDraft,
  ICustomerDocumentsListParams,
  IAppointmentAcceptData,
  IConfirmDefectsData,
  FeedbackData,
} from '@common/interfaces';
import { decoupledClientInstance } from '@common/utils/axios';

class CustomerRepository extends AxiosRepository implements ICustomerRepository {
  public constructor(protected authClient: AxiosInstance) {
    super((decoupledClientInstance as any).create(), authClient);
  }

  async getBookings(params?: ICustomerBookingListParams) {
    const { data } = await this.get(`/customer-area/bookings/`, { params });
    return data;
  }

  async getBookingDetails(bookingId: string) {
    const { data } = await this.get(`/customer-area/bookings/${bookingId}/`);
    return data;
  }

  async getFilters(params?: ICustomerBookingFiltersParams) {
    const { data } = await this.get(`/customer-area/bookings/filters/`, { params });
    return data;
  }

  async getComments(bookingId: string) {
    const { data } = await this.get(`/customer-area/bookings/${bookingId}/comments/`, {
      params: { limit: 300 },
    });
    return data;
  }

  async getPfi(pfiId: string) {
    const { data } = await this.get(`/customer-area/pfis/${pfiId}/`);
    return data;
  }

  async sendComment(bookingId: string, comment: ICommentDraft) {
    const { data } = await this.post(`/customer-area/bookings/${bookingId}/comments/`, comment);
    return data;
  }

  async getOptions(bookingId: string) {
    const { data } = await this.get(`/customer-area/bookings/${bookingId}/options/`);
    return data;
  }

  async getPendingActions() {
    const { data } = await this.get(`/customer-area/bookings/pending-actions/`);
    return data;
  }

  async getInvoices(params?: ICustomerDocumentsListParams) {
    const { data } = await this.get(`/customer-area/invoices/`, { params });
    return data;
  }

  async getStatements(params?: ICustomerDocumentsListParams) {
    const { data } = await this.get(`/customer-area/statements/`, { params });
    return data;
  }

  async acceptAppointment(appointmentUuid: string, acceptingData: IAppointmentAcceptData) {
    const { data } = await this.patch(`/customer-area/appointments/${appointmentUuid}/accept/`, acceptingData);
    return data;
  }

  async getBookingDefects(bookingId: string) {
    const { data } = await this.get(`/customer-area/bookings/${bookingId}/defects/`);
    return data;
  }

  async confirmBookingDefects(bookingId: string, confirmData: IConfirmDefectsData) {
    const { data } = await this.patch(`/customer-area/bookings/${bookingId}/defects/confirm/`, confirmData);
    return data;
  }

  async uploadPfi(pfiId: string, payload: FormData) {
    const { data } = await this.post(`/customer-area/pfis/${pfiId}/upload/`, payload);
    return data;
  }

  async getFeedbackQuestions() {
    // Note: it's retrieve only 20 questions. If we need more - we should make multistep form on FE
    const { data } = await this.get(`/customer-area/bookings/feedback/questions/`, {
      params: {
        limit: 20,
      },
    });
    return data;
  }

  async postFeedback(payload: FeedbackData) {
    const { data } = await this.post(`/customer-area/bookings/feedback/`, payload);
    return data;
  }
}

export default CustomerRepository;
