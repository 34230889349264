import { DefaultListParams, Nullable } from '../common';

export enum IAppointmentType {
  CHECK_IN = 'check_in',
  CHECK_OUT = 'check_out',
}

export enum IAppointmentURLType {
  CHECK_IN = 'check-in',
  CHECK_OUT = 'check-out',
}

export interface IMechanic {
  country: Nullable<unknown>;
  depot_location: string;
  first_name: string;
  fitter_name: string;
  is_active: boolean;
  last_name: string;
  latest_sync_time: Nullable<string>;
  location: string;
  rate: Nullable<unknown>;
  record_number: number;
  technician_id: Nullable<unknown>;
  uuid: string;
}

export interface IAppointment {
  booking: {
    city_name: null;
    country_name: string;
    facility_name: string;
    has_checkout_appointment: boolean;
    latitude: string;
    longitude: string;
    plate_number: string;
    requesting_agency: string;
    type_of_asset: string;
    uuid: string;
  };
  from_to_datetime_range: {
    from: string;
    to: string;
  };
  appointment_type: IAppointmentType;
  assign_to: Nullable<Omit<IMechanic, 'is_active'>>;
  jobcard_job_number: Nullable<string>;
  make: string;
  model: string;
  proposed_check_in_dates: Nullable<string[]>;
  uuid: string;
}

export interface IDraftAppointment {
  from_to_datetime_range: {
    lower: string;
    upper: string;
  };
  assign_to?: string;
  booking: string;
  appointment_type: IAppointmentType;
}

export interface AppointmentsTodayStat {
  checkins: number;
  checkouts: number;
}

export interface AppointmentListParams extends DefaultListParams {
  appointment_datetime_after?: string;
  appointment_datetime_before?: string;
  appointment_type?: string;
}
