import { FC, useState } from 'react';
import { useBanner, MaintenanceBanner } from '@unbooking/ui-kit';
import { Button, Main } from '@components';
import { MarkerIcon, WrenchCarIcon, WarningIcon, CheckInIcon, MaintenanceIcon, InvoiceIcon } from '@assets/svg';
import { LINKS, URLS } from '@common/constants';
import { PendingActions, PendingAction } from '@common/interfaces';
import { useQuery } from 'react-query';
import { useGlobalContext, useRepository } from '@context';
import { Link } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import css from './styles.module.scss';

const MAX_ITEMS_TO_SHOW = 4;

interface IDataPanel {
  data: PendingAction[];
}

const DataPanel: FC<IDataPanel> = ({ data }) => {
  const [open, setOpen] = useState(false);

  const preparedData = open ? data : data.slice(0, MAX_ITEMS_TO_SHOW);
  const isMore = data.length > MAX_ITEMS_TO_SHOW;

  return (
    <>
      {preparedData.map(({ uuid, request_number, pending_date }) => {
        const days = differenceInDays(new Date(), new Date(pending_date));

        return (
          <div key={uuid}>
            <Link to={LINKS.customerBooking(uuid)}>{request_number}</Link>
            {` - pending for ${days ? days : 1} day(s)`}
          </div>
        );
      })}
      {isMore ? (
        open ? (
          <Button className={css.buttonView} text='View less' variant='text' onClick={() => setOpen(false)} />
        ) : (
          <Button className={css.buttonView} text='View more' variant='text' onClick={() => setOpen(true)} />
        )
      ) : null}
    </>
  );
};

const CustomerAreaEntryPage: FC = () => {
  const { customerRepository } = useRepository();
  const {
    user: { customer_area_location, organisation },
  } = useGlobalContext();

  const { bannerMessage, closeBanner } = useBanner({
    switchName: 'maintenance_notice_workshops',
    switchesUrl: `${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`,
    monolithApiKey: String(process.env.REACT_APP_HBH_KEY),
  });

  const { data: pendingActions, isLoading } = useQuery<PendingActions>(['stats'], () =>
    customerRepository.getPendingActions()
  );

  return (
    <Main loading={isLoading} withoutPaddings background='customer'>
      {bannerMessage ? <MaintenanceBanner message={bannerMessage} onClose={closeBanner} /> : null}
      <div className={css.container}>
        <div className={css.content}>
          <div className={css.title}>
            WFP <b>Workshop</b>
            <br /> Self Service Area
          </div>
          <div className={css.place}>
            <MarkerIcon />
            {customer_area_location
              ? `${organisation?.short_name || ''} ${customer_area_location.country_name || ''}${
                  customer_area_location.city_name ? ', ' + customer_area_location.city_name : ''
                }`
              : null}
          </div>
          <div className={css.blocks}>
            <div className={css.block}>
              <div className={css.label}>Book maintenance</div>
              <div className={css.description}>Create new maintenance request.</div>
              <div className={css.bottom}>
                <Button
                  className={css.button}
                  link={URLS.BOOK_MAINTENANCE}
                  iconR={<MaintenanceIcon />}
                  text='Book maintenance'
                  variant='lochmara'
                />
              </div>
            </div>
            <div className={css.block}>
              <div className={css.label}>My maintenance requests</div>
              <div className={css.description}>Track your requests and perform actions.</div>
              <div className={css.bottom}>
                <Button
                  className={css.button}
                  iconR={<WrenchCarIcon />}
                  link={URLS.CUSTOMER_AREA_BOOKINGS}
                  text='View maintenance requests'
                  variant='forest'
                />
              </div>
            </div>
            <div className={css.block}>
              <div className={css.label}>My invoices</div>
              <div className={css.description}>View your invoices or statements for your requests.</div>
              <div className={css.bottom}>
                <Button
                  className={css.button}
                  iconR={<InvoiceIcon />}
                  link={URLS.CUSTOMER_AREA_DOCUMENTS}
                  text='View invoices'
                  variant='kumera'
                />
              </div>
            </div>
          </div>
        </div>
        {pendingActions ? (
          <div className={css.sidebar}>
            <div className={css.sidebarTitle}>
              <WarningIcon />
              Pending actions
            </div>
            {pendingActions.check_in_date_to_accept.length ? (
              <div className={css.infoBlock}>
                <div className={css.subtitle}>
                  Check-in date to accept <CheckInIcon />
                </div>
                <DataPanel data={pendingActions.check_in_date_to_accept} />
              </div>
            ) : null}
            {pendingActions.known_issues_to_review.length ? (
              <div className={css.infoBlock}>
                <div className={css.subtitle}>
                  New defects to review <WrenchCarIcon />
                </div>
                <DataPanel data={pendingActions.known_issues_to_review} />
              </div>
            ) : null}
            {pendingActions.pfi_to_approve.length ? (
              <div className={css.infoBlock}>
                <div className={css.subtitle}>
                  PFI to approve <InvoiceIcon />
                </div>
                <DataPanel data={pendingActions.pfi_to_approve} />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Main>
  );
};

export default CustomerAreaEntryPage;
