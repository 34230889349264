import React, { FC, useState } from 'react';
import { Button, Modal, PropositionCalendar, Tip } from '@components';
import { ArrowRightIcon } from '@assets/svg';
import { IAppointmentAcceptData, Nullable, WorkSchedule } from '@common/interfaces';
import { DEFAULT_SERVER_DATE_FORMAT } from '@common/constants';
import { addDays, format } from 'date-fns';
import { getDisabledDays } from '@common/utils';
import css from './styles.module.scss';

interface IProposeDatesModal {
  acceptAppointment: (data: IAppointmentAcceptData) => void;
  closeModal: () => void;
  workSchedule: Nullable<WorkSchedule>;
}

const ProposeDatesModal: FC<IProposeDatesModal> = ({ acceptAppointment, closeModal, workSchedule }) => {
  const [dates, setDates] = useState<Date[]>([]);

  const onSubmit = () => {
    const proposed_check_in_dates = [dates[0], dates[1]]
      .filter(Boolean)
      .map((date) => format(date, DEFAULT_SERVER_DATE_FORMAT));

    acceptAppointment({ proposed_check_in_dates, accepted: false });
    closeModal();
  };

  const disabledDays = getDisabledDays(workSchedule);
  const today = new Date();
  const maxDate = addDays(today, 30);
  const isInvalid = dates.length < 1;

  const setMaxTwoDates = (dates: Date[]) => {
    if (dates.length > 2) return;
    setDates(dates);
  };

  return (
    <Modal title='Propose new check-in date(s)' closeModal={closeModal}>
      <Modal.Content>
        <div className={css.note}>
          Please provide us with up to two dates when you would be available to bring your asset to the workshop.
        </div>
        <PropositionCalendar
          minDate={today}
          maxDate={maxDate}
          onChange={setMaxTwoDates}
          dates={dates}
          disabledDays={disabledDays}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
        <Tip text='Please select at least one date' isVisible={isInvalid}>
          <Button text='Send proposal' iconR={<ArrowRightIcon />} onClick={onSubmit} disabled={isInvalid} />
        </Tip>
      </Modal.Footer>
    </Modal>
  );
};

export default ProposeDatesModal;
