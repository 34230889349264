import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IGlobalRepository } from '@common/interfaces';
import { decoupledClientInstance } from '@common/utils/axios';

class GlobalRepository extends AxiosRepository implements IGlobalRepository {
  public constructor(protected authClient: AxiosInstance) {
    super((decoupledClientInstance as any).create(), authClient);
  }

  async getCurrentUser() {
    const { data } = await this.get(`/accounts/me/`);
    return data;
  }

  async getWaffles() {
    const { data } = await this.get(`/waffle_status`);
    return data;
  }
}

export default GlobalRepository;
