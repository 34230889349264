import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { useAuth } from '@unbooking/ui-auth';
import { CallbackPage, CMSPage, LoginPage } from '@pages';
import { FacilityProvider, GlobalProvider, RepositoryProvider, useGlobalContext } from '@context';
import routes from './routes';
import { Footer, Header } from '@components';
import { handleError } from '@common/utils';
import { WaffleFlags } from '@common/interfaces';
import { useAppType } from '@common/hooks';
import { AppType, URLS } from '@common/constants';
import { useEffect } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2, // Note: hook for getting data in token refresh process
    },
  },
  queryCache: new QueryCache({
    onError: (err) => handleError(err),
  }),
  mutationCache: new MutationCache({
    onError: (err) => handleError(err),
  }),
});

const Pages = () => {
  const { isAuthenticated } = useAuth();
  const {
    waffles,
    user: { customer_area_location },
    isUserSuccess,
  } = useGlobalContext();
  const appType = useAppType();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserSuccess && appType === AppType.CUSTOMER_AREA && !customer_area_location) {
      navigate(URLS.FORBIDDEN);
    }
  }, [isUserSuccess, customer_area_location]);

  return (
    <>
      {isAuthenticated ? <Header /> : null}
      <Routes>
        {isAuthenticated ? (
          <>
            {/* Note: the home page is missing in App, we should set it on CMS side! */}
            <Route path='/' element={<Navigate to={'/home'} />} />
            {routes.map(({ Component, path, waffle }, i) => {
              const pageWaffle = waffles.flags[waffle as keyof WaffleFlags];
              const isWaffleVisible = !pageWaffle || (pageWaffle && pageWaffle.is_active);
              if (isWaffleVisible) return <Route path={path} key={i} element={<Component />} />;
              return null;
            })}
            <Route path='callback/*' element={<CallbackPage />} />
            <Route path='*' element={<CMSPage />} />
          </>
        ) : (
          <>
            <Route index element={<LoginPage />} />
            <Route path='callback/*' element={<CallbackPage />} />
            <Route path='*' element={<Navigate to='/' />} />
          </>
        )}
      </Routes>
      {isAuthenticated ? <Footer /> : null}
    </>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RepositoryProvider>
        <FacilityProvider>
          <GlobalProvider>
            <Pages />
          </GlobalProvider>
        </FacilityProvider>
      </RepositoryProvider>
    </QueryClientProvider>
  );
}

export default App;
