import React, { FC, useEffect } from 'react';
import { useField } from 'informed';
import { DefaultKnownIssue, IDropdownOption, InformedFieldState } from '@common/interfaces';
import { Button, Dropdown } from '@components';
import { CloseIcon } from '@assets/svg';
import css from './styles.module.scss';
import { MAX_DEFECTS_COUNT } from '@common/constants';

interface IFreeOptionsField {
  name: string;
  options: IDropdownOption[];
  currentCount: number;
}

const defaultOption = {
  text: '',
  type_of_repair: '',
};

const getErrorText = (error: unknown) => {
  if (typeof error === 'string') return error;
  const errors: string[] = [];
  // : Fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error as any).forEach((obj: any) => {
    for (const key in obj) {
      errors.push(obj[key]);
    }
  });
  return errors.filter((item, idx) => errors.indexOf(item) === idx).join(', ');
};

const FreeOptionsField: FC<IFreeOptionsField> = (props) => {
  const { options, currentCount } = props;

  const { fieldState, fieldApi, render } = useField(props);

  const { value: fieldValue = [], error, showError } = fieldState as InformedFieldState<DefaultKnownIssue[]>;

  const { setValue, setTouched } = fieldApi;

  const updateArrayState = (idx: number, field: string, value: string) => {
    const updatedArray = [...fieldValue];
    updatedArray[idx] = { ...updatedArray[idx], [field]: value };
    setValue(updatedArray);
  };

  useEffect(() => {
    if (fieldValue.length === 0 && currentCount !== MAX_DEFECTS_COUNT) {
      setValue([defaultOption]);
    }
  }, [fieldValue]);

  return render(
    <div className={css.field}>
      {fieldValue.map(({ type_of_repair, text }, idx) => (
        <div key={idx} className={css.option}>
          <input
            className={css.text}
            type='text'
            value={text}
            onBlur={(e) => setTouched(true, e)}
            onChange={(e) => updateArrayState(idx, 'text', e.target.value)}
          />
          <div className={css.dropdown}>
            <Dropdown
              key={type_of_repair}
              value={options.find(({ value }) => value === type_of_repair)}
              placeholder='Service type...'
              options={options}
              onChange={(option) => updateArrayState(idx, 'type_of_repair', option.value)}
            />
          </div>
          <Button
            iconL={<CloseIcon />}
            className={css.button}
            variant='icon'
            disabled={fieldValue.length === 1}
            onClick={() => {
              const newValue = [...fieldValue];
              newValue.splice(idx, 1);
              setValue(newValue);
            }}
          />
        </div>
      ))}
      {fieldValue.length < MAX_DEFECTS_COUNT - currentCount ? (
        <>
          <Button
            text='+ Add more faults to be checked'
            variant='text'
            onClick={() => setValue([...fieldValue, defaultOption])}
          />
        </>
      ) : (
        <div className={css.note}>You can add no more than {MAX_DEFECTS_COUNT} faults</div>
      )}
      {showError ? <div className={css.error}>{getErrorText(error)}</div> : null}
    </div>
  );
};

export default FreeOptionsField;
