import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, TextArea, Tip } from '@components';
import { useRepository } from '@context';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FeedbackData, FeedbackQuestion, PaginatedResponse } from '@common/interfaces';
import ReactStars from 'react-rating-stars-component';
import css from './styles.module.scss';

interface ISurveyModal {
  bookingId: string;
  closeModal: () => void;
  refetch: () => void;
}

interface ExtendedFeedbackQuestion extends FeedbackQuestion {
  comment: string;
  rating: number;
}

const SurveyModal: FC<ISurveyModal> = ({ bookingId, closeModal, refetch }) => {
  const { customerRepository } = useRepository();

  const [questions, setQuestions] = useState<ExtendedFeedbackQuestion[]>([]);

  const { data, isLoading: isQuestionsLoading } = useQuery<PaginatedResponse<FeedbackQuestion>>(
    `${bookingId}-questions`,
    () => customerRepository.getFeedbackQuestions()
  );

  const { mutate: postFeedback, isLoading: postFeedbackInProgress } = useMutation(
    (data: FeedbackData) => customerRepository.postFeedback(data),
    {
      onSuccess: () => {
        toast.success('Feedback was sent');
        refetch();
        closeModal();
      },
    }
  );

  const setRating = (uuid: string, rating: number) => {
    setQuestions((prevArray) => [...prevArray].map((item) => (item.uuid === uuid ? { ...item, rating } : item)));
  };

  const setComment = (uuid: string, comment: string) => {
    setQuestions((prevArray) => [...prevArray].map((item) => (item.uuid === uuid ? { ...item, comment } : item)));
  };

  const onSubmit = () => {
    postFeedback({
      answers: questions.map(({ uuid, rating, comment }) => ({
        question: uuid,
        rating: rating || undefined,
        comment: comment || undefined,
      })),
      booking: bookingId,
    });
  };

  useEffect(() => {
    if (data) setQuestions(data.results.map((data) => ({ ...data, comment: '', rating: 0 })));
  }, [data]);

  const isInvalid = questions
    .filter(({ required }) => required)
    .some(({ comment, need_comment, rating, need_rating }) => (need_comment && !comment) || (need_rating && !rating));

  const loading = isQuestionsLoading || postFeedbackInProgress;

  return (
    <Modal loading={loading} className={css.modal} title='We value your feedback!' closeModal={closeModal}>
      <Modal.Content>
        <div className={css.note}>
          We are always looking to improve Workshop Services. To do that, we need your precious feedback. We would love
          to learn more about your experience with our service.
        </div>
        {questions.map(({ comment, rating, need_comment, need_rating, text, uuid, required }) => {
          return (
            <div className={css.question} key={uuid}>
              <div className={css.label}>
                {text} {!required ? <span>(optional)</span> : '*'}
              </div>
              <div className={css.content}>
                {need_rating ? (
                  <ReactStars value={rating} size={50} onChange={(value: number) => setRating(uuid, value)} />
                ) : null}
                {need_comment ? <TextArea value={comment} onChange={(value) => setComment(uuid, value)} /> : null}
              </div>
            </div>
          );
        })}
      </Modal.Content>
      <Modal.Footer>
        <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
        <Tip text='Please fill all mandatory fields' isVisible={isInvalid}>
          <Button text='Send' onClick={onSubmit} disabled={isInvalid} />
        </Tip>
      </Modal.Footer>
    </Modal>
  );
};

export default SurveyModal;
