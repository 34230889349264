import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ColumnType } from 'antd/lib/table/interface';
import {
  BookingPendingActions,
  DateRange,
  IComment,
  ICustomerBooking,
  ICustomerBookingFilters,
  ICustomerBookingListTab,
  IDropdownOption,
  Nullable,
  PaginatedResponse,
  PendingActions,
  SessionStorageKey,
} from '@common/interfaces';
import { useGlobalContext, useRepository } from '@context';
import {
  Button,
  CheckboxDropdown,
  CustomerStatusDropdown,
  KPIBlock,
  Main,
  MonthPicker,
  Page,
  SearchField,
  Switcher,
  Table,
  TablePagination,
  Tabs,
  Tip,
} from '@components';
import { CheckInIcon, CommentIcon, InvoiceIcon, MarkerIcon, WarningIcon, WrenchCarIcon } from '@assets/svg';
import {
  DEFAULT_SERVER_DATE_FORMAT,
  DEFAULT_USER_DATE_FORMAT,
  DEFAULT_USER_DATE_TIME_FORMAT,
  LINKS,
} from '@common/constants';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import css from './styles.module.scss';

const tabList = [
  { name: 'To be processed', key: ICustomerBookingListTab.TO_PROCESS },
  { name: 'In progress', key: ICustomerBookingListTab.IN_PROGRESS },
  { name: 'Closed', key: ICustomerBookingListTab.CLOSED },
];

const DEFAULT_PAGE_SIZE = 10;

const pendingActionsLabels: Record<string, string> = {
  confirm_check_in_date: 'Confirm check-in date',
  review_known_issues: 'Review new defects',
  approve_pfi: 'Approve PFI',
};

const getPendingActionLabels = (actions: BookingPendingActions): string[] => {
  return Object.entries(actions)
    .filter(([, value]) => value)
    .map(([key]) => pendingActionsLabels[key]);
};

interface ICommentsCell {
  comments: number;
  last_comment: Nullable<IComment>;
}

const CommentsCell: FC<ICommentsCell> = ({ comments, last_comment }) => {
  return (
    <Tip
      isVisible={Boolean(last_comment)}
      content={
        last_comment ? (
          <div className={css.tipContent}>
            <div className={css.tipTitle}>
              {last_comment.created_by.full_name}
              <div className={css.date}>{format(new Date(last_comment.created), DEFAULT_USER_DATE_TIME_FORMAT)}</div>
            </div>
            {last_comment.body}
          </div>
        ) : null
      }
    >
      <CommentIcon /> {comments}
    </Tip>
  );
};

interface IStatusCell {
  status: string;
  actions: BookingPendingActions;
}

const StatusCell: FC<IStatusCell> = ({ status, actions }) => {
  const labels = getPendingActionLabels(actions);

  return (
    <div className={css.statusCell}>
      <CustomerStatusDropdown status={status} />
      {labels.length > 0 ? (
        <Tip
          content={
            <div className={css.tipContent}>
              <div className={css.tipTitle}>Action required</div>
              {labels.map((action, idx) => (
                <div key={idx}>{`- ${action}`}</div>
              ))}
            </div>
          }
        >
          <WarningIcon />
        </Tip>
      ) : null}
    </div>
  );
};

const columns: ColumnType<ICustomerBooking>[] = [
  {
    dataIndex: 'request_number',
    key: 'request_number',
    title: 'Request #',
    sorter: true,
  },
  {
    dataIndex: 'requestor_surname',
    key: 'requestor_surname',
    title: 'Requestor',
    render: (_, { requestor_name, requestor_surname }) => `${requestor_name} ${requestor_surname}`,
  },
  {
    dataIndex: 'requested',
    key: 'created',
    title: 'Request date',
    render: (_, { created }) => format(new Date(created), DEFAULT_USER_DATE_FORMAT),
    sorter: true,
  },
  {
    dataIndex: 'facility',
    key: 'facility',
    title: 'Workshop',
    render: (_, { facility }) => facility.city_name,
  },
  {
    dataIndex: 'jobcard',
    key: 'jobcard',
    title: 'Jobcard #',
    render: (_, { jobcard }) => jobcard || '-',
  },
  {
    dataIndex: 'plate_number',
    key: 'plate_number',
    title: 'Plate/ID',
    sorter: true,
  },
  {
    dataIndex: 'make',
    key: 'make',
    title: 'Asset',
    render: (_, { make, model }) => `${make} ${model}`,
  },
  {
    dataIndex: 'comments',
    key: 'comments',
    title: 'Comments',
    render: (_, { comments, last_comment }) => <CommentsCell comments={comments} last_comment={last_comment} />,
  },
  {
    dataIndex: 'state',
    key: 'state',
    title: 'Status',
    sorter: true,
    render: (_, { state, pending_actions }) => <StatusCell status={state} actions={pending_actions} />,
  },
];

const CustomerAreaBookingListPage: FC = () => {
  const {
    user: { customer_area_location, organisation },
  } = useGlobalContext();

  const { customerRepository } = useRepository();
  const navigate = useNavigate();
  const params = JSON.parse(sessionStorage.getItem(SessionStorageKey.CUSTOMER_BOOKING_LIST_PARAMS) || '{}') || {};

  const [tab, setTab] = useState<ICustomerBookingListTab>((params.tab as ICustomerBookingListTab) ?? tabList[0].key);
  const [page, setPage] = useState(params.page || 1);
  const [pageSize, setPageSize] = useState(params.pageSize || DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState(params.order || '');
  const [search, setSearch] = useState('');
  const [requestorFilter, setRequestorFilter] = useState<IDropdownOption[]>(params.requestorFilter || []);
  const [statusFilter, setStatusFilter] = useState<IDropdownOption[]>(params.statusFilter || []);
  const [workshopFilter, setWorkshopFilter] = useState<IDropdownOption[]>(params.workshopFilter || []);
  const [dateFilter, setDateFilter] = useState<Nullable<DateRange>>(params.dateFilter || null);
  const [pendingFilter, setPendingFilter] = useState(params.pendingFilter || false);

  const { data: bookings, isLoading: isDataLoading } = useQuery<PaginatedResponse<ICustomerBooking>>(
    [
      'bookings',
      tab,
      order,
      page,
      pageSize,
      requestorFilter,
      statusFilter,
      workshopFilter,
      dateFilter,
      search,
      pendingFilter,
    ],
    () =>
      customerRepository.getBookings({
        limit: pageSize,
        offset: (page - 1) * 10,
        ordering: order,
        search,
        requestor: requestorFilter.map(({ value }) => value).join(','),
        state: statusFilter.map(({ value }) => value).join(','),
        facilities: workshopFilter.map(({ value }) => value).join(','),
        created_after: dateFilter?.created_after,
        created_before: dateFilter?.created_before,
        tab_type: tab,
        pending_actions: pendingFilter ? pendingFilter : undefined,
      })
  );

  const { data: filtersData, isLoading: isFiltersLoading } = useQuery<ICustomerBookingFilters>(
    ['booking-filters', tab],
    () =>
      customerRepository.getFilters({
        tab_type: tab,
      })
  );

  const { data: kpi, isLoading: isKpiLoading } = useQuery<PendingActions>(['stats'], () =>
    customerRepository.getPendingActions()
  );

  const clearPagination = () => {
    setPage(1);
    setPageSize(DEFAULT_PAGE_SIZE);
    setOrder('');
  };

  const clearFilters = () => {
    setRequestorFilter([]);
    setStatusFilter([]);
    setWorkshopFilter([]);
    setDateFilter(null);
    setPendingFilter(false);
  };

  const clearAllParams = () => {
    clearPagination();
    clearFilters();
  };

  const tabChange = (tab: string) => {
    setTab(tab as ICustomerBookingListTab);
    clearAllParams();
  };

  useEffect(() => {
    sessionStorage.setItem(
      SessionStorageKey.CUSTOMER_BOOKING_LIST_PARAMS,
      JSON.stringify({
        tab,
        order,
        page,
        pageSize,
        requestorFilter,
        statusFilter,
        workshopFilter,
        dateFilter,
        pendingFilter,
      })
    );
  }, [tab, order, page, pageSize, requestorFilter, statusFilter, workshopFilter, dateFilter]);

  const loading = isDataLoading || isFiltersLoading || isKpiLoading;

  const isFiltered =
    requestorFilter.length || workshopFilter.length || statusFilter.length || dateFilter || pendingFilter;

  const kpiData = kpi
    ? [
        {
          label: 'Check-in date to approve',
          value: kpi.check_in_date_to_accept.length,
          icon: <CheckInIcon />,
          color: 'sycamore',
        },
        {
          label: 'Defects to review',
          value: kpi.known_issues_to_review.length,
          icon: <WrenchCarIcon />,
          color: 'bluch',
        },
        {
          label: 'PFIs to confirm',
          value: kpi.pfi_to_approve.length,
          icon: <InvoiceIcon />,
          color: 'lochmara',
        },
      ]
    : [];

  return (
    <Main background='customer'>
      <Page>
        <div className={css.top}>
          <div>
            <div className={css.title}>My maintenance requests</div>
            <div className={css.info}>
              <MarkerIcon />
              {customer_area_location
                ? `${organisation?.short_name || ''} ${customer_area_location.country_name || ''}${
                    customer_area_location.city_name ? ', ' + customer_area_location.city_name : ''
                  }`
                : null}
            </div>
          </div>
          <KPIBlock items={kpiData} />
        </div>
        <Tabs tabList={tabList} tabKey={tab} onTabChange={tabChange} />
        <section className={css.tab}>
          <div className={css.topPanel}>
            <div className={css.filters}>
              <span>Filters:</span>
              <CheckboxDropdown
                items={filtersData?.requesters}
                onChange={(options) => {
                  clearPagination();
                  setRequestorFilter(options);
                }}
                selectedItems={requestorFilter}
                title='Requestor'
              />
              <MonthPicker
                showDates
                initialFrom={dateFilter?.created_after ? new Date(dateFilter.created_after) : null}
                initialTo={dateFilter?.created_before ? new Date(dateFilter.created_before) : null}
                setMonths={(from, to) => {
                  if (from && to) {
                    setDateFilter({
                      created_after: format(from, DEFAULT_SERVER_DATE_FORMAT),
                      created_before: format(to, DEFAULT_SERVER_DATE_FORMAT),
                    });
                  }
                }}
              />
              <CheckboxDropdown
                items={filtersData?.facilities}
                onChange={(options) => {
                  clearPagination();
                  setWorkshopFilter(options);
                }}
                selectedItems={workshopFilter}
                title='Workshop'
              />
              <CheckboxDropdown
                items={filtersData?.states}
                onChange={(options) => {
                  clearPagination();
                  setStatusFilter(options);
                }}
                selectedItems={statusFilter}
                title='Status'
              />
              {isFiltered ? <Button text='Reset filters' variant='text' onClick={() => clearAllParams()} /> : null}
            </div>
            <div className={css.filters}>
              <Switcher
                label='Show only pending actions'
                hideTexts
                checked={pendingFilter}
                onChange={setPendingFilter}
              />
              <SearchField onChange={setSearch} value={search} placeholder='Search booking...' />
            </div>
          </div>
          <Table
            className={css.table}
            columns={columns}
            data={bookings?.results}
            loading={loading}
            onChangeColumnOrder={setOrder}
            onRowClick={({ uuid }) => navigate(LINKS.customerBooking(uuid))}
            rowClassName={(record) => {
              const actions = getPendingActionLabels(record.pending_actions);
              if (actions.length > 0) return css.pendingRow;
              return '';
            }}
          />
          <TablePagination
            page={page}
            onChangePage={setPage}
            totalCount={bookings?.count}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
          />
        </section>
      </Page>
    </Main>
  );
};

export default CustomerAreaBookingListPage;
