import { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Button,
  DefaultField,
  Dropdown,
  Empty,
  Loader,
  Main,
  NumberField,
  Page,
  TextArea,
  TextField,
  Tip,
  TotalCostsBox,
} from '@components';
import {
  ArrowLeftIcon,
  UserIcon,
  WrenchCarIcon,
  SuccessIcon,
  PlusIcon,
  CloseRoundIcon,
  CompletedIcon,
  EditIcon,
  TrashIcon,
} from '@assets/svg';
import {
  IBookingDetails,
  IFocalPoint,
  Nullable,
  PaginatedResponse,
  PfiLineItem,
  ProFormaInvoice,
} from '@common/interfaces';
import { useMutation, useQuery } from 'react-query';
import { useFacilityContext, useGlobalContext, useRepository } from '@context';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Form, FormState, useFormState } from 'informed';
import { toast } from 'react-toastify';
import { formatMoneyStringToNumber, formatToMoneyString } from '@common/utils';
import * as Yup from 'yup';
import { useDownloadFile } from '@common/hooks';
import { LINKS } from '@common/constants';
import css from './styles.module.scss';

interface DraftLineItem {
  description?: string;
  quantity?: number;
  unit_cost?: string;
  uuid: string;
}

type Item = DraftLineItem | PfiLineItem;

interface ILineItemForm {
  idx: number;
  item: Item;
  pfiId: string;
  refetch: () => void;
  setItems: Dispatch<SetStateAction<Item[]>>;
}

const yupSchema = Yup.object().shape({
  quantity: Yup.string().required(),
  description: Yup.string().required(),
  unit_cost: Yup.string().required(),
});

const LineItemForm: FC<ILineItemForm> = ({ idx, item, pfiId, refetch, setItems }) => {
  const { facilityId } = useFacilityContext();
  const { pfiRepository } = useRepository();

  const { uuid, description, quantity, unit_cost, total_cost } = item as PfiLineItem;

  const isDraft = uuid.includes('draft');

  const [isEdit, setIsEdit] = useState(isDraft);

  const { mutate: addPfiLineItem, isLoading: isAddingLineItemInProgress } = useMutation(
    (item: Partial<PfiLineItem>) => pfiRepository.addPfiLineItem(facilityId, pfiId, item),
    {
      onSuccess: () => {
        toast.success('Line item was added');
        setIsEdit(false);
        refetch();
      },
    }
  );

  const { mutate: updatePfiLineItem, isLoading: isUpdatingLineItemInProgress } = useMutation(
    (item: Partial<PfiLineItem>) => pfiRepository.updatePfiLineItem(facilityId, pfiId, item),
    {
      onSuccess: () => {
        toast.success('Line item was updated');
        setIsEdit(false);
        refetch();
      },
    }
  );

  const { mutate: deletePfiLineItem, isLoading: isDeletingLineItemInProgress } = useMutation(
    () => pfiRepository.deletePfiLineItem(facilityId, pfiId, uuid),
    {
      onSuccess: () => {
        toast.warning('Line item was deleted');
        refetch();
      },
    }
  );

  const onSubmit = (data: FormState) => {
    const { description, quantity, unit_cost } = data.values as unknown as Item;
    const payload = {
      description,
      quantity: formatMoneyStringToNumber(quantity),
      unit_cost: formatMoneyStringToNumber(unit_cost),
    };
    if (isDraft) {
      addPfiLineItem(payload);
    } else {
      updatePfiLineItem({
        ...payload,
        uuid,
      });
    }
    setIsEdit(false);
  };

  const deleteItem = () => {
    if (isDraft) {
      setItems((prevArray) => [...prevArray].filter((item) => item.uuid !== uuid));
    } else {
      setItems((prevArray) => [...prevArray].filter((item) => item.uuid !== uuid));
      deletePfiLineItem();
    }
  };

  const discardItem = () => {
    setIsEdit(false);
    if (isDraft) {
      setItems((prevArray) => [...prevArray].filter((item) => item.uuid !== uuid));
    }
  };

  const TotalField = () => {
    const { values } = useFormState();
    const { quantity, unit_cost } = values as unknown as Item;
    if (!quantity || !unit_cost) {
      if (!total_cost) return <>-</>;
      return <>{formatToMoneyString(total_cost)}</>;
    }
    const total = formatToMoneyString(formatMoneyStringToNumber(quantity) * formatMoneyStringToNumber(unit_cost));
    return <>{total}</>;
  };

  const ButtonConfirm = () => {
    const { values } = useFormState();
    const { quantity, unit_cost, description } = values as unknown as Item;

    // Note: pay attention: we have mixed types here - quantity and unit_cost can be number or string
    const isNotValid = !parseFloat(`${quantity}`) || unit_cost === undefined || !description;

    return (
      <Tip isVisible={isNotValid} text='All fields should be filled. Quantity can not be equal zero.'>
        <Button
          text='Confirm'
          iconL={<CompletedIcon />}
          type='submit'
          variant='text'
          className={css.buttonSuccess}
          disabled={isNotValid}
        />
      </Tip>
    );
  };

  const loading = isAddingLineItemInProgress || isUpdatingLineItemInProgress || isDeletingLineItemInProgress;

  return (
    <Loader loading={loading}>
      <Form
        yupSchema={yupSchema}
        initialValues={item as unknown as Record<string, unknown>}
        className={`${css.row} ${isEdit ? css.editable : ''}`}
        onSubmit={onSubmit}
        key={isEdit.toString()}
      >
        <div className={css.td}>{idx + 1}</div>
        <div className={css.td}>{isEdit ? <TextField name='description' /> : description}</div>
        <div className={css.td}>
          {isEdit ? <NumberField name='quantity' allowDecimal skipFormatting /> : quantity?.toLocaleString('en')}
        </div>
        <div className={css.td}>
          {isEdit ? <NumberField name='unit_cost' allowDecimal /> : formatToMoneyString(unit_cost)}
        </div>
        <div className={css.td}>
          <TotalField />
        </div>
        <div className={css.td}>
          <div className={css.actions}>
            {isEdit ? (
              <>
                <Button text='Discard' iconL={<CloseRoundIcon />} onClick={discardItem} variant='text' />
                <ButtonConfirm />
              </>
            ) : (
              <>
                <Button
                  text='Edit'
                  iconL={<EditIcon />}
                  onClick={() => setIsEdit(true)}
                  variant='text'
                  className={css.buttonEdit}
                />
                <Button text='Delete' iconL={<TrashIcon />} onClick={deleteItem} variant='text' />
              </>
            )}
          </div>
        </div>
      </Form>
    </Loader>
  );
};

const GeneratePFIPage: FC = () => {
  const navigate = useNavigate();
  const { id: bookingId = '', pfiId = '' } = useParams();
  const { bookingRepository, pfiRepository, facilityRepository } = useRepository();
  const { facilityId } = useFacilityContext();
  const { user } = useGlobalContext();

  const [items, setItems] = useState<Item[]>([]);
  const [pfiData, setPfiData] = useState<Nullable<Omit<ProFormaInvoice, 'line_items'>>>(null);
  const [workshopMarginValue, setWorkshopMarginValue] = useState(pfiData?.workshop_margin_percentage || 0);
  const [mcrMarginIncluded, setMcrMarginIncluded] = useState(true);

  const { data: focalPoints, isLoading: isFocalPointsLoading } = useQuery<PaginatedResponse<IFocalPoint>>(
    'focal-points',
    () => facilityRepository.getFocalPoints(facilityId)
  );

  const { data: bookingDetails, isLoading: isDetailsLoading } = useQuery<IBookingDetails>(`request-${bookingId}`, () =>
    bookingRepository.getBookingDetails(facilityId, bookingId)
  );

  const {
    data: savedPfiData,
    isLoading: isPfiLoading,
    refetch,
  } = useQuery<ProFormaInvoice>(`pfi-${pfiId}`, () => pfiRepository.getPfi(facilityId, pfiId), {
    onSuccess: ({ line_items, ...rest }) => {
      setItems(line_items);
      if (!pfiData) setPfiData({ ...rest });
    },
  });

  const { mutate: preparingData, isLoading: isPreparingDataInProgress } = useMutation(
    () =>
      pfiRepository.updatePfi(facilityId, pfiId, {
        ...pfiData,
        is_mcr_needed: mcrMarginIncluded,
        workshop_margin_percentage: parseFloat(workshopMarginValue as unknown as string),
      }),
    {
      onSuccess: () => generatePfiPdf(),
    }
  );

  const { mutate: generatePfiPdf, isLoading: isGenerationPdfInProgress } = useMutation(
    () => pfiRepository.generatePfiPdf(facilityId, pfiId),
    {
      onSuccess: ({ pdf }) => {
        toast.success('PDF was generated successfully');
        useDownloadFile(pdf, `${bookingDetails?.request_number}_pfi.pdf`);
        setTimeout(() => navigate(LINKS.booking(bookingId)), 1000);
      },
    }
  );

  const addNewItem = () => setItems((prevArray) => [...prevArray, { uuid: `draft${uuid()}` }]);

  const approversOptions = focalPoints
    ? focalPoints.results.map(({ uuid, first_name, last_name }) => ({
        label: `${first_name} ${last_name}`,
        value: uuid,
      }))
    : [];

  const totalLineItems = items.reduce((acc, { quantity = 0, unit_cost }) => {
    return acc + quantity * formatMoneyStringToNumber(unit_cost);
  }, 0);

  const isGeneratingPossible = Boolean(
    pfiData?.approved_by && savedPfiData?.line_items && savedPfiData?.line_items.length > 0
  );

  const loading =
    isDetailsLoading || isPfiLoading || isFocalPointsLoading || isPreparingDataInProgress || isGenerationPdfInProgress;

  return (
    <Main loading={loading}>
      <Page>
        {bookingDetails ? (
          <>
            <Button
              text='Back to booking detail'
              variant='text'
              iconL={<ArrowLeftIcon />}
              link={LINKS.booking(bookingId)}
            />
            <div className={css.booking}>Booking {bookingDetails.request_number}</div>
            <div className={css.title}>Generate Pro-forma Invoice</div>
            <div className={css.info}>
              <div>
                <UserIcon />
                {`${bookingDetails.requestor_details.name} ${bookingDetails.requestor_details.surname}, ${bookingDetails.requesting_agency}`}
              </div>
              <div>
                <WrenchCarIcon />
                {`${bookingDetails.plate_number}, ${bookingDetails.make?.label || ''} ${
                  bookingDetails.model?.label || ''
                }`}
              </div>
            </div>
          </>
        ) : null}
        <Button
          text='Add new line item'
          variant='text'
          iconR={<PlusIcon />}
          onClick={addNewItem}
          className={css.buttonAdd}
        />
        <div className={css.tableWrapper}>
          <div className={css.table}>
            <div className={css.thead}>
              <div className={css.th}>#</div>
              <div className={css.th}>Description</div>
              <div className={css.th}>Quantity</div>
              <div className={css.th}>Unit Cost (USD)</div>
              <div className={css.th}>Total Cost (USD)</div>
              <div className={css.th}>Actions</div>
            </div>
            <div className={css.tbody}>
              {items.length > 0 ? (
                items.map((item, idx) => (
                  <LineItemForm
                    item={item}
                    idx={idx}
                    key={item.uuid}
                    pfiId={pfiId}
                    setItems={setItems}
                    refetch={refetch}
                  />
                ))
              ) : (
                <Empty text='No line items' />
              )}
            </div>
          </div>
        </div>
        {pfiData ? (
          <div className={css.content}>
            <div className={css.pfiInfo}>
              <div className={css.field}>
                <div className={css.label}>Prepared by</div>
                <DefaultField text={user.full_name} />
              </div>
              <div className={css.field}>
                <div className={css.label}>Approved by</div>
                <Dropdown
                  value={approversOptions.find(({ value }) => pfiData.approved_by === value)}
                  options={approversOptions}
                  onChange={(option) =>
                    setPfiData((data) => {
                      if (data) {
                        return { ...data, approved_by: option.value };
                      } else {
                        return null;
                      }
                    })
                  }
                />
              </div>
              <div className={css.field}>
                <div className={css.label}>
                  PFI remarks <span>(optional)</span>
                </div>
                <TextArea
                  value={pfiData.pdf_remarks}
                  onChange={(value) =>
                    setPfiData((data) => {
                      if (data) {
                        return { ...data, pdf_remarks: value };
                      } else {
                        return null;
                      }
                    })
                  }
                />
              </div>
            </div>
            <TotalCostsBox
              mcrMarginValue={pfiData.mcr_fee_percentage}
              setWorkshopMarginValue={setWorkshopMarginValue}
              totalLineItems={totalLineItems}
              workshopMarginValue={workshopMarginValue}
              mcrMarginIncluded={mcrMarginIncluded}
              setMcrMarginIncluded={setMcrMarginIncluded}
            />
          </div>
        ) : null}
        <Tip
          isVisible={!isGeneratingPossible}
          text='For generation at least one line item and approver should be added'
        >
          <Button
            text='Generate PFI'
            iconR={<SuccessIcon />}
            variant='forest'
            onClick={() => preparingData()}
            disabled={!isGeneratingPossible}
          />
        </Tip>
      </Page>
    </Main>
  );
};

export default GeneratePFIPage;
