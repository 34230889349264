import { AxiosInstance } from 'axios';
import React, { createContext, FC, useContext } from 'react';
import {
  AppointmentRepository,
  BookingRepository,
  CMSRepository,
  CustomerRepository,
  FacilityRepository,
  InvoiceRepository,
  JobcardRepository,
  MonolithRepository,
  PfiRepository,
  StatementRepository,
  GlobalRepository,
} from '@common/repositories';
import { useAuth } from '@unbooking/ui-auth';

const getRepositories = (axios: AxiosInstance) => ({
  appointmentRepository: new AppointmentRepository(axios),
  bookingRepository: new BookingRepository(axios),
  cmsRepository: new CMSRepository(axios),
  customerRepository: new CustomerRepository(axios),
  facilityRepository: new FacilityRepository(axios),
  invoiceRepository: new InvoiceRepository(axios),
  jobcardRepository: new JobcardRepository(axios),
  monolithRepository: new MonolithRepository(axios),
  pfiRepository: new PfiRepository(axios),
  statementRepository: new StatementRepository(axios),
  globalRepository: new GlobalRepository(axios),
});

const RepositoryContext = createContext({} as ReturnType<typeof getRepositories>);

interface IRepositoryProvider {
  children?: React.ReactNode;
}

const RepositoryProvider: FC<IRepositoryProvider> = ({ children }) => {
  const { axios } = useAuth();

  return <RepositoryContext.Provider value={getRepositories(axios)}>{children}</RepositoryContext.Provider>;
};

const useRepository = () => useContext(RepositoryContext);

export { RepositoryProvider, useRepository };
