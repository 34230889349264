import React, { FC, useEffect } from 'react';
import { useField } from 'informed';
import { CloseIcon, DocUploadIcon } from '@assets/svg';
import { InformedFieldState } from '@common/interfaces';
import css from './styles.module.scss';

interface IUploadField {
  name: string;
  maxSize?: number;
  maxCount?: number;
  label?: string;
  accept?: string;
}

const UploadField: FC<IUploadField> = ({ accept, name, maxSize, maxCount, label = 'Select file' }) => {
  const { fieldState, fieldApi, render, ref } = useField({ name });

  const { value: fieldValue = [], error, showError } = fieldState as InformedFieldState<Array<File>>;

  const { setValue, setError } = fieldApi;

  useEffect(() => {
    if (!fieldValue) {
      setValue([]);
    }
  }, [fieldValue]);

  return render(
    <div ref={ref} className={css.field}>
      <label htmlFor={name} className={`${css.label} ${maxCount && fieldValue.length >= maxCount ? css.disabled : ''}`}>
        {label}
        <DocUploadIcon />
      </label>
      <input
        className={css.input}
        id={name}
        type='file'
        multiple
        accept={accept}
        onChange={(e) => setValue([...fieldValue, ...Array.from(e.target.files || [])])}
      />
      {fieldValue.map(({ name, size }, idx) => {
        const oversized = maxSize && size >= maxSize;
        return (
          <div key={idx} className={`${css.file} ${oversized ? css.inputError : ''}`}>
            <div className={css.text}>{name}</div>
            {oversized && <span className={css.fileOversized}>oversized</span>}
            <CloseIcon
              className={css.fileDelete}
              title='Delete file'
              onClick={() => {
                const newValue = [...fieldValue];
                newValue.splice(idx, 1);
                setValue(newValue);
                setError(undefined);
              }}
            />
          </div>
        );
      })}
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

export default UploadField;
