import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ColumnType } from 'antd/lib/table/interface';
import {
  IDropdownOption,
  Nullable,
  PaginatedResponse,
  IStatement,
  IStatementOptions,
  IInvoice,
  IInvoiceOptions,
  DateRange,
  SessionStorageKey,
  IInvoiceBookingData,
} from '@common/interfaces';
import { useFacilityContext, useRepository } from '@context';
import {
  Button,
  CheckboxDropdown,
  Main,
  Modal,
  MonthPicker,
  Page,
  SearchField,
  Table,
  TablePagination,
  Tabs,
} from '@components';
import {
  CloseRoundIcon,
  ContractIcon,
  ContractsStackIcon,
  DocAddIcon,
  DocDownloadIcon,
  MarkerIcon,
  TrashIcon,
  WrenchCarIcon,
} from '@assets/svg';
import {
  DEFAULT_SERVER_DATE_FORMAT,
  DEFAULT_USER_DATE_FORMAT,
  DEFAULT_USER_MONTH_FORMAT,
  LINKS,
  URLS,
} from '@common/constants';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { formatToMoneyString } from '@common/utils';
import css from './styles.module.scss';

enum IStatementListTab {
  STATEMENTS = 'STATEMENTS',
  INVOICES = 'INVOICES',
}

interface IDeleteDocumentModalData {
  uuid: string;
  invoice_number: string;
}

const tabList = [
  { name: 'Invoices', key: IStatementListTab.INVOICES },
  { name: 'Statements', key: IStatementListTab.STATEMENTS },
];

const DEFAULT_PAGE_SIZE = 10;

const DocumentListPage: FC = () => {
  const { facilityId, facility } = useFacilityContext();
  const { statementRepository, invoiceRepository } = useRepository();
  const params = JSON.parse(sessionStorage.getItem(SessionStorageKey.DOCUMENTS_LIST_PARAMS) || '{}') || {};

  const [tab, setTab] = useState<IStatementListTab>((params.tab as IStatementListTab) ?? tabList[0].key);
  const [page, setPage] = useState(params.page || 1);
  const [pageSize, setPageSize] = useState(params.pageSize || DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState(params.order || '');
  const [search, setSearch] = useState('');

  const [customerFilter, setCustomerFilter] = useState<Array<IDropdownOption>>(params.customerFilter || []);
  const [dateFilter, setDateFilter] = useState<Nullable<DateRange>>(params.dateFilter || null);

  const [createDocumentModalData, setCreateDocumentModalData] = useState(false);
  const [deleteDocumentModalData, setDeleteDocumentModalData] = useState<Nullable<IDeleteDocumentModalData>>(null);

  const { city_name, country_name, name } = facility;

  const isStatement = tab === IStatementListTab.STATEMENTS;
  const isInvoice = tab === IStatementListTab.INVOICES;

  const getListRequest = isStatement
    ? () =>
        statementRepository.getStatements(facilityId, {
          limit: pageSize,
          offset: (page - 1) * 10,
          ordering: order,
          search,
          customer: customerFilter.map(({ value }) => value).join(','),
          generated_time_after: dateFilter?.created_after,
          generated_time_before: dateFilter?.created_before,
        })
    : () =>
        invoiceRepository.getInvoices(facilityId, {
          limit: pageSize,
          offset: (page - 1) * 10,
          ordering: order,
          search,
          customer: customerFilter.map(({ value }) => value).join(','),
          generated_time_after: dateFilter?.created_after,
          generated_time_before: dateFilter?.created_before,
        });

  const getOptionsRequest = isStatement
    ? () => statementRepository.getOptions(facilityId)
    : () => invoiceRepository.getOptions(facilityId);

  const deleteDocumentRequest = isStatement
    ? (statementId: string) => statementRepository.deleteStatement(facilityId, statementId)
    : (invoiceId: string) => invoiceRepository.deleteInvoice(facilityId, invoiceId);

  const {
    data: documents,
    isLoading: isDataLoading,
    refetch,
  } = useQuery<PaginatedResponse<IStatement | IInvoice>>(
    ['documents', tab, order, page, pageSize, customerFilter, dateFilter, search],
    getListRequest
  );

  const { data: statementOptions, isLoading: isOptionsLoading } = useQuery<IStatementOptions | IInvoiceOptions>(
    [`options-${tab}`, tab],
    getOptionsRequest
  );

  const { mutate: deleteDocument, isLoading: isDeletingInProgress } = useMutation(deleteDocumentRequest, {
    onSuccess: () => {
      toast.warning('Document was deleted successfully');
      refetch();
    },
  });

  const columns: ColumnType<IStatement | IInvoice>[] = [
    {
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      title: 'Invoice #',
    },
    ...(isInvoice
      ? [
          {
            dataIndex: 'job_number',
            key: 'job_number',
            title: 'Jobcard #',
          },
          {
            dataIndex: 'booking',
            key: 'plate_number',
            title: 'Plate ID',
            render: (booking: IInvoiceBookingData) => booking.plate_number,
          },
          {
            dataIndex: 'booking',
            key: 'booking',
            title: 'Booking',
            render: (booking: IInvoiceBookingData) => (
              <Button text={booking.request_number} variant='text' link={LINKS.booking(booking.uuid)} />
            ),
          },
        ]
      : []),
    {
      dataIndex: 'customer',
      key: 'customer',
      title: 'Customer',
    },
    {
      dataIndex: 'generated_time',
      key: 'generated_time',
      title: 'Generated at',
      render: (generated_time) => (generated_time ? format(new Date(generated_time), DEFAULT_USER_DATE_FORMAT) : '-'),
    },
    ...(isStatement
      ? [
          {
            dataIndex: 'reference_period',
            key: 'reference_period',
            title: 'Reference period',
            render: (reference_period: string) => format(new Date(reference_period), DEFAULT_USER_MONTH_FORMAT),
          },
          {
            dataIndex: 'number_of_jobcards',
            key: 'number_of_jobcards',
            title: 'No of jobcards',
          },
        ]
      : [
          {
            dataIndex: 'workshop',
            key: 'workshop',
            title: 'Location',
          },
        ]),
    ...(isStatement
      ? [
          {
            dataIndex: 'grand_total',
            key: 'grand_total',
            title: 'Total cost',
            render: (grand_total: number) => formatToMoneyString(grand_total),
          },
        ]
      : [
          {
            dataIndex: 'grand_total',
            key: 'grand_total',
            title: 'Total cost',
            render: (grand_total: number) => formatToMoneyString(grand_total),
          },
        ]),
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (_, { uuid, invoice_number, pdf }) => (
        <div className={css.buttons}>
          <Button
            text='PDF'
            iconR={<DocDownloadIcon />}
            variant='text'
            link={pdf || ''}
            disabled={!pdf}
            target='_blank'
          />
          <Button
            text='Delete'
            iconR={<TrashIcon />}
            variant='text'
            onClick={() => setDeleteDocumentModalData({ uuid, invoice_number })}
          />
        </div>
      ),
    },
  ];

  const clearPagination = () => {
    setPage(1);
    setPageSize(DEFAULT_PAGE_SIZE);
    setOrder('');
  };

  const clearFilters = () => {
    setCustomerFilter([]);
    setDateFilter(null);
  };

  const clearAllParams = () => {
    clearPagination();
    clearFilters();
    setSearch('');
  };

  const tabChange = (tab: string) => {
    setTab(tab as IStatementListTab);
    clearAllParams();
  };

  useEffect(() => {
    sessionStorage.setItem(
      SessionStorageKey.DOCUMENTS_LIST_PARAMS,
      JSON.stringify({
        tab,
        order,
        limit: pageSize,
        offset: (page - 1) * 10,
        customerFilter,
        dateFilter,
      })
    );
  }, [tab, order, page, pageSize, customerFilter, dateFilter]);

  const place = `${city_name}, ${country_name}`;
  const isFiltered = customerFilter.length || dateFilter;
  const loading = isDataLoading || isOptionsLoading || isDeletingInProgress;

  return (
    <Main>
      <Page>
        <div className={css.top}>
          <div>
            <div className={css.title}>Invoices and statements</div>
            <div className={css.info}>
              <div>
                <WrenchCarIcon />
                {name}
              </div>
              <div>
                <MarkerIcon />
                {place}
              </div>
            </div>
          </div>
          <Button
            text='New invoice or statement'
            onClick={() => setCreateDocumentModalData(true)}
            iconR={<DocAddIcon />}
          />
        </div>
        <Tabs tabList={tabList} tabKey={tab} onTabChange={tabChange} />
        <section className={css.tab}>
          <div className={css.topPanel}>
            <div className={css.filters}>
              <span>Filters:</span>
              <CheckboxDropdown
                items={statementOptions?.filters.customers}
                onChange={(options) => {
                  clearPagination();
                  setCustomerFilter(options);
                }}
                selectedItems={customerFilter}
                title='Customer'
              />
              <MonthPicker
                showDates
                triggerText='Date of generation'
                initialFrom={dateFilter?.created_after ? new Date(dateFilter.created_after) : null}
                initialTo={dateFilter?.created_before ? new Date(dateFilter.created_before) : null}
                setMonths={(from, to) => {
                  if (from && to) {
                    setDateFilter({
                      created_after: format(from, DEFAULT_SERVER_DATE_FORMAT),
                      created_before: format(to, DEFAULT_SERVER_DATE_FORMAT),
                    });
                  }
                }}
              />
              {isFiltered ? <Button text='Reset filters' variant='text' onClick={() => clearAllParams()} /> : null}
            </div>
            <SearchField
              className={css.search}
              onChange={setSearch}
              value={search}
              placeholder='Search invoice or statement...'
            />
          </div>
          <Table
            className={css.table}
            columns={columns}
            data={documents?.results}
            loading={loading}
            onChangeColumnOrder={setOrder}
          />
          <TablePagination
            page={page}
            onChangePage={setPage}
            totalCount={documents?.count}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
          />
        </section>
        {createDocumentModalData ? (
          <Modal title='Generate invoice or statement' closeModal={() => setCreateDocumentModalData(false)}>
            <Modal.Content>
              <div className={css.choices}>
                <Link to={URLS.GENERATE_STATEMENT} className={css.choice}>
                  <div className={css.title}>Statement</div>
                  <div className={css.description}>Generate a statement for multiple jobcards</div>
                  <div className={css.bottom}>
                    <ContractsStackIcon />
                    <div className={css.plus}>+</div>
                  </div>
                </Link>
                <Link to={URLS.GENERATE_INVOICE} className={css.choice}>
                  <div className={css.title}>Invoice</div>
                  <div className={css.description}>Generate an invoice for a single jobcard</div>
                  <div className={css.bottom}>
                    <ContractIcon />
                    <div className={css.plus}>+</div>
                  </div>
                </Link>
              </div>
            </Modal.Content>
          </Modal>
        ) : null}
        {deleteDocumentModalData ? (
          <Modal title='Delete document?' closeModal={() => setDeleteDocumentModalData(null)}>
            <Modal.Content>
              Do you confirm the deletion of the document <b>{deleteDocumentModalData.invoice_number}</b>?
            </Modal.Content>
            <Modal.Footer>
              <Button text='Cancel' variant='transparent-negative' onClick={() => setDeleteDocumentModalData(null)} />
              <Button
                text='Confirm'
                iconR={<CloseRoundIcon />}
                variant='alizarin'
                onClick={() => {
                  deleteDocument(deleteDocumentModalData.uuid);
                  setDeleteDocumentModalData(null);
                }}
              />
            </Modal.Footer>
          </Modal>
        ) : null}
      </Page>
    </Main>
  );
};

export default DocumentListPage;
