import { TAuthConfig } from '@unbooking/ui-auth';

const scope = 'email openid profile';

const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  authorizationEndpoint: process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? '',
  refreshTokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? '',
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  logoutEndpoint: process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT ?? '',
  logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI ?? '',
  unbhApiUrl: process.env.REACT_APP_HBH_API ?? '',
  unbhApiKey: process.env.REACT_APP_HBH_KEY ?? '',
  scope,
  configurations: {
    b2c: {
      authorizationEndpoint: process.env.REACT_APP_B2C_AUTHORIZATION_ENDPOINT || '',
      tokenEndpoint: process.env.REACT_APP_B2C_TOKEN_ENDPOINT ?? '',
      clientId: process.env.REACT_APP_B2C_CLIENT_ID || '',
      logoutEndpoint: process.env.REACT_APP_B2C_LOGOUT_ENDPOINT ?? '',
      logoutUri: process.env.REACT_APP_B2C_LOGOUT_URI || '',
      scope: `${scope} offline_access`,
    },
    google: {
      authorizationEndpoint: process.env.REACT_APP_GOOGLE_AUTHORIZATION_ENDPOINT ?? '',
      tokenEndpoint: process.env.REACT_APP_GOOGLE_TOKEN_ENDPOINT ?? '',
      clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? '',
      clientSecret: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_SECRET ?? '',
      scope,
    },
  },
};

export default authConfig;
