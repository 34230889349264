import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IMonolithRepository } from '@common/interfaces';
import { monolithClientInstance } from '@common/utils/axios';

class MonolithRepository extends AxiosRepository implements IMonolithRepository {
  public constructor(protected authClient: AxiosInstance) {
    super((monolithClientInstance as any).create(), authClient);
  }

  async getManagedFacilities() {
    const { data } = await this.get('/v2/user-profile/managed-facilities/', {
      params: {
        category: 'workshop',
        page_size: 10000,
      },
    });
    return data;
  }
}

export default MonolithRepository;
