import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Empty } from '@components';
import { SendIcon, UserIcon } from '@assets/svg';
import { format } from 'date-fns';
import { DEFAULT_USER_DATE_TIME_FORMAT } from '@common/constants';
import { Mention, MentionsInput } from 'react-mentions';
import { IComment, ICommentDraft, IDefaultComment, IFocalPoint } from '@common/interfaces';
import css from './styles.module.scss';
import { useGlobalContext } from '@context';

export enum ChatType {
  CUSTOMER = 'customer',
  WORKSHOP = 'workshop',
}

interface IChat {
  comments: IComment[];
  defaultComments?: IDefaultComment[];
  focalPoints?: Array<IFocalPoint>;
  sendComment: (comment: ICommentDraft) => void;
  placeholder?: string;
  type: ChatType;
}

interface Message {
  text: string;
  isDefault?: boolean;
  fromCustomer?: boolean;
  toCustomer?: boolean;
}

const Message: FC<Message> = ({ text, isDefault = false, fromCustomer = false, toCustomer = false }) => {
  const regex = /@\[([^[]+)\]\(([^)]+)\)/g;
  const parts = text.split(regex);

  return (
    <div
      className={`${css.text} ${isDefault ? css.default : ''} ${fromCustomer ? css.fromCustomer : ''} ${
        toCustomer ? css.toCustomer : ''
      }`}
    >
      {parts.map((part, index) => {
        // If the part of the string matches the regular expression, create bold text
        if (index % 3 === 1) {
          return <b key={index}>{part}</b>;
        } else if (index % 3 === 2) {
          // If the part of the string contains an identifier, skip it
          return null;
        } else {
          // If the part is simple text just return it
          return part;
        }
      })}
    </div>
  );
};

const Chat: FC<IChat> = ({
  comments,
  defaultComments = [],
  focalPoints = [],
  placeholder = 'Write a comment...',
  sendComment,
  type,
}) => {
  const { waffles } = useGlobalContext();

  const [comment, setComment] = useState('');
  const [visibleToCustomer, setVisibleToCustomer] = useState(false);

  const mentionList = focalPoints.map(({ first_name, last_name, uuid }) => ({
    display: `${first_name} ${last_name}`,
    id: uuid,
  }));

  const handleSend = () => {
    sendComment({
      body: comment,
      visible_for_customer_area: visibleToCustomer,
    });
    setComment('');
    if (visibleToCustomer) setVisibleToCustomer(false);
  };

  // TO DO: Check scrolling process for DO
  const scrollableContentRef = useRef(null);
  useEffect(() => {
    if (scrollableContentRef) {
      (scrollableContentRef.current as unknown as HTMLFormElement)?.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [comments]);

  const itemsCount = comments.length + defaultComments.length;

  return (
    <div className={css.chat}>
      <div className={css.content}>
        {itemsCount === 0 ? (
          <Empty text='No updates available at the moment' />
        ) : (
          <div className={css.comments}>
            {defaultComments.map((comment, idx) => (
              <div className={css.comment} key={comment.uuid}>
                <div className={css.commentSide}>
                  <div className={`${css.icon} ${css.default}`}>{comment.label}</div>
                  {idx !== defaultComments.length - 1 || comments.length > 0 ? <div className={css.dots} /> : null}
                </div>
                <div className={css.commentMain}>
                  <div className={css.info}>
                    <b>{comment.author}</b>
                  </div>
                  <Message text={comment.body} isDefault />
                </div>
              </div>
            ))}
            {comments.map((comment, idx) => (
              <div className={css.comment} key={comment.uuid}>
                <div className={css.commentSide}>
                  {comment.from_customer_area ? (
                    <div className={`${css.icon} ${css.fromCustomer}`}>
                      <UserIcon />
                    </div>
                  ) : (
                    <div className={css.icon} />
                  )}
                  {idx !== comments.length - 1 ? <div className={css.dots} /> : null}
                </div>
                <div className={css.commentMain}>
                  <div className={css.info}>
                    <div>
                      {`${comment.created_by.first_name} ${comment.created_by.last_name} ${
                        comment.visible_for_customer_area && type === ChatType.WORKSHOP ? 'to customer' : ''
                      }`}
                    </div>
                    <div className={css.date}>{format(new Date(comment.created), DEFAULT_USER_DATE_TIME_FORMAT)}</div>
                  </div>
                  <Message
                    text={comment.body}
                    toCustomer={comment.visible_for_customer_area}
                    fromCustomer={comment.from_customer_area}
                  />
                </div>
              </div>
            ))}
            <div ref={scrollableContentRef} className={css.anchor} />
          </div>
        )}
      </div>
      <div className={css.action}>
        <MentionsInput
          a11ySuggestionsListLabel='Suggestions'
          className='react-mention'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={placeholder}
        >
          <Mention className={css.tagged} trigger='@' data={mentionList} />
        </MentionsInput>
        <Button iconR={<SendIcon />} disabled={comment.length < 2} className={css.button} onClick={handleSend} />
      </div>
      {type === ChatType.WORKSHOP && waffles.flags.CUSTOMER_AREA.is_active ? (
        <div className={css.controls}>
          <Checkbox
            label='Visible for Customer Area'
            checked={visibleToCustomer}
            onChange={(checked) => setVisibleToCustomer(checked)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Chat;
