import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IDraftInvoice, IInvoiceListParams, IInvoiceOtherCost, IInvoiceRepository } from '@common/interfaces';
import { decoupledClientInstance } from '@common/utils/axios';

class InvoiceRepository extends AxiosRepository implements IInvoiceRepository {
  public constructor(protected authClient: AxiosInstance) {
    super((decoupledClientInstance as any).create(), authClient);
  }

  async getInvoices(facilityId: string, params?: IInvoiceListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/invoices/`, { params });
    return data;
  }

  async createInvoice(facilityId: string, invoice?: IDraftInvoice) {
    const { data } = await this.post(`/facilities/${facilityId}/invoices/`, invoice);
    return data;
  }

  async deleteInvoice(facilityId: string, invoiceId: string) {
    const { data } = await this.delete(`/facilities/${facilityId}/invoices/${invoiceId}/`);
    return data;
  }

  async getOptions(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/invoices/options/`);
    return data;
  }

  async generateInvoicePdf(facilityId: string, invoiceId: string) {
    const { data } = await this.post(`/facilities/${facilityId}/invoices/${invoiceId}/generate/`);
    return data;
  }

  async addOtherCost(facilityId: string, invoiceId: string, item: Partial<IInvoiceOtherCost>) {
    const { data } = await this.post(`/facilities/${facilityId}/invoices/${invoiceId}/other-costs/`, item);
    return data;
  }

  async updateOtherCost(facilityId: string, invoiceId: string, item: Partial<IInvoiceOtherCost>) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/invoices/${invoiceId}/other-costs/${item.uuid}/`,
      item
    );
    return data;
  }

  async deleteOtherCost(facilityId: string, invoiceId: string, itemId: string) {
    const { data } = await this.delete(`/facilities/${facilityId}/invoices/${invoiceId}/other-costs/${itemId}/`);
    return data;
  }
}

export default InvoiceRepository;
