import React, { FC } from 'react';
import { Button, Modal, UploadField } from '@components';
import { DocIcon, SuccessIcon } from '@assets/svg';
import { Form, FormState, useFormState } from 'informed';
import { useFacilityContext, useRepository } from '@context';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Nullable } from '@common/interfaces';
import css from './styles.module.scss';

interface IUploadFileModal {
  bookingId?: string;
  closeModal: () => void;
  note?: string;
  pfiId?: Nullable<string>;
  refetch: () => void;
}

interface UploadFormValues {
  files: File[];
}

const MAX_FILE_SIZE = 5242880; // 5mb
const ALLOWED_FORMATS = ['.pdf', '.jpeg', '.jpg', '.doc', '.docx', '.png', '.tiff', '.gif'];

const allowedFormats = ALLOWED_FORMATS.join(', ');

const yupSchema = Yup.object().shape({
  files: Yup.array().test(
    'files',
    ({ value }) => {
      if (!value) return 'Required';
      return `File should have format ${allowedFormats} and be less 5 mb`;
    },
    function (files?: File[]) {
      if (!files) return false;
      if (files.every(({ size }) => size > MAX_FILE_SIZE)) return false;
      if (!files.every(({ name }) => ALLOWED_FORMATS.some((format) => name.toLowerCase().includes(format))))
        return false;
      return true;
    }
  ),
});

const UploadFileModal: FC<IUploadFileModal> = ({ closeModal, note, pfiId, bookingId = '', refetch }) => {
  const { facilityId } = useFacilityContext();
  const { pfiRepository, bookingRepository } = useRepository();

  const request = pfiId
    ? (data: FormData) => pfiRepository.addPfiDocument(facilityId, pfiId, data)
    : (data: FormData) => bookingRepository.addBookingFile(facilityId, bookingId, data);

  const { mutate: uploadFile, isLoading: isUploadingFile } = useMutation(request, {
    onSuccess: () => {
      toast.success('File was uploaded successfully');
      closeModal();
      refetch();
    },
  });

  const onSubmit = ({ values }: FormState) => {
    const formData = new FormData();
    formData.append('file', (values as unknown as UploadFormValues).files[0]);
    uploadFile(formData);
  };

  const SubmitButton = () => {
    const formState = useFormState();
    const files = (formState.values as unknown as UploadFormValues).files;
    const disabled = !files || files.length === 0;
    return <Button text='Confirm and save' iconR={<SuccessIcon />} disabled={disabled} type='submit' />;
  };

  return (
    <Modal title='Upload file' closeModal={closeModal} loading={isUploadingFile}>
      <Form onSubmit={onSubmit} yupSchema={yupSchema}>
        <Modal.Content>
          <DocIcon className={css.icon} />
          {note && <div className={css.note}>{note}</div>}
          <UploadField accept={allowedFormats} name='files' maxCount={1} maxSize={MAX_FILE_SIZE} />
        </Modal.Content>
        <Modal.Footer>
          <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
          <SubmitButton />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UploadFileModal;
