import React, { FC } from 'react';
import { DEFAULT_DATE_PICKER_FORMAT, DEFAULT_TIME_INTERVAL } from '@common/constants';
import { CalendarIcon, ClockIcon } from '@assets/svg';
import { useField } from 'informed';
import DatePicker from 'react-datepicker';
import { InformedFieldState, Nullable } from '@common/interfaces';
import css from './styles.module.scss';

interface IDateField {
  minDate?: Date;
  maxDate?: Date;
  name: string;
  type?: 'date' | 'time';
  minTime?: Date;
  maxTime?: Date;
  filterDate?: (date: Date) => boolean;
  onChange?: (date: Nullable<Date>) => void;
  inline?: boolean;
  openToDate?: Date;
}

const CUSTOM_PROPS = {
  date: {
    placeholderText: 'dd/mm/yyyy',
    dateFormat: DEFAULT_DATE_PICKER_FORMAT,
  },
  time: {
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: DEFAULT_TIME_INTERVAL,
    timeCaption: 'Time',
    dateFormat: 'HH:mm',
    timeFormat: 'HH:mm',
    placeholderText: 'hh:mm',
  },
};

const DateField: FC<IDateField> = ({
  minDate,
  maxDate,
  filterDate,
  onChange,
  name,
  type = 'date',
  minTime,
  maxTime,
  inline = false,
  openToDate,
}) => {
  const { fieldApi, fieldState, ref, render } = useField({ name });

  const { value, error, showError } = fieldState as InformedFieldState<Nullable<Date>>;

  const { setValue, setTouched } = fieldApi;

  return render(
    <div className={`${css.field} ${inline ? css.inline : ''}`}>
      <DatePicker
        onFocus={() => setTouched(true)}
        onChange={(date) => {
          setValue(date);
          onChange && onChange(date);
        }}
        selected={value}
        ref={ref}
        autoComplete='off'
        filterDate={filterDate}
        shouldCloseOnSelect
        dropdownMode='select'
        showYearDropdown={!inline}
        minTime={minTime}
        maxTime={maxTime}
        minDate={minDate}
        maxDate={maxDate}
        openToDate={openToDate}
        calendarStartDay={1}
        inline={inline}
        name={name}
        className={`${css.input} ${showError ? css.inputError : ''}`}
        {...CUSTOM_PROPS[type]}
      />
      {!inline ? type === 'time' ? <ClockIcon className={css.icon} /> : <CalendarIcon className={css.icon} /> : null}
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

export default DateField;
