import { createContext, ReactNode, useContext } from 'react';
import { useRepository } from './repository.context';
import { useQuery } from 'react-query';
import { Loader } from '@components';
import { User, Waffles } from '@common/interfaces';
import { useAuth } from '@ui-auth';

interface GlobalProviderProps {
  children: ReactNode;
}

type GlobalContextType = {
  waffles: Waffles;
  user: User;
  isUserSuccess: boolean;
};

// Note: need true for pages for correct routing work
const defaultFlag = { is_active: true, last_modified: '' };

const defaultGlobalContext: GlobalContextType = {
  waffles: {
    flags: {
      CUSTOMER_AREA: defaultFlag,
      MOBILE_RESPONSIVENESS: defaultFlag,
    },
    switches: {},
    samples: {},
  },
  user: {
    customer_area_location: null,
    uuid: '',
    email: '',
    first_name: '',
    last_name: '',
    full_name: '',
    is_active: true,
    organisation: null,
  },
  isUserSuccess: false,
};

const mergeContext = (serverContext: GlobalContextType) => {
  return {
    waffles: {
      flags: { ...defaultGlobalContext.waffles.flags, ...serverContext.waffles.flags },
      switches: { ...defaultGlobalContext.waffles.switches, ...serverContext.waffles.switches },
      samples: { ...defaultGlobalContext.waffles.samples, ...serverContext.waffles.samples },
    },
    user: {
      ...defaultGlobalContext.user,
      ...serverContext.user,
    },
    isUserSuccess: serverContext.isUserSuccess,
  };
};

const GlobalContext = createContext<GlobalContextType>(defaultGlobalContext);

const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const { globalRepository } = useRepository();
  const { isAuthenticated } = useAuth();

  const {
    data: user = defaultGlobalContext.user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useQuery<User>('user', () => globalRepository.getCurrentUser(), { enabled: isAuthenticated });

  const { data: waffles = defaultGlobalContext.waffles, isLoading: isWafflesLoading } = useQuery<Waffles>(
    ['waffles'],
    () => globalRepository.getWaffles(),
    { enabled: isAuthenticated }
  );

  const value = mergeContext({ waffles, user, isUserSuccess });
  const loading = isWafflesLoading || isUserLoading;

  return (
    <GlobalContext.Provider value={value}>
      {loading ? <Loader full /> : null}
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
