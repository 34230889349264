import { FC } from 'react';
import {
  AnalyticsPage,
  BookMaintenancePage,
  BookingDetailsPage,
  BookingFormPage,
  BookingListPage,
  BookingPlannerPage,
  BookingSuccessPage,
  CustomerAreaBookingDetailsPage,
  CustomerAreaBookingListPage,
  CustomerAreaDocumentListPage,
  CustomerAreaEntryPage,
  DocumentListPage,
  EntryPage,
  GenerateInvoicePage,
  GeneratePFIPage,
  GenerateStatementPage,
  NoAccessPage,
  NotFoundPage,
  PlannerPage,
} from '@pages';
import { URLS } from '@common/constants';
import { WaffleFlags } from '@common/interfaces';

interface Page {
  path: string;
  name: string;
  Component: FC;
  waffle?: keyof WaffleFlags;
}

export default [
  // Base
  // Note: The root (home) page is missing because it is on the CMS side.
  {
    path: URLS.NOT_FOUND,
    name: 'Not Found',
    Component: NotFoundPage,
  },
  {
    path: URLS.FORBIDDEN,
    name: 'Forbidden',
    Component: NoAccessPage,
  },

  // Platform
  {
    path: URLS.BOOK_MAINTENANCE,
    name: 'Book Maintenance',
    Component: BookMaintenancePage,
  },
  {
    path: URLS.BOOK_MAINTENANCE_FORM,
    name: 'Booking Form',
    Component: BookingFormPage,
  },
  {
    path: URLS.BOOK_MAINTENANCE_SUCCESS,
    name: 'Booking Success',
    Component: BookingSuccessPage,
  },

  // Digital Office
  {
    path: URLS.DO,
    name: 'Digital Office',
    Component: EntryPage,
  },
  {
    path: URLS.ANALYTICS,
    name: 'Analytics',
    Component: AnalyticsPage,
  },
  {
    path: URLS.BOOKINGS,
    name: 'Booking List',
    Component: BookingListPage,
  },
  {
    path: URLS.BOOKING,
    name: 'Booking Details',
    Component: BookingDetailsPage,
  },
  {
    path: URLS.BOOKING_PLANNER,
    name: 'Booking Planner',
    Component: BookingPlannerPage,
  },
  {
    path: URLS.GENERATE_PFI,
    name: 'Generate PFI',
    Component: GeneratePFIPage,
  },
  {
    path: URLS.DOCUMENTS,
    name: 'Document List',
    Component: DocumentListPage,
  },
  {
    path: URLS.GENERATE_STATEMENT,
    name: 'Generate Statement',
    Component: GenerateStatementPage,
  },
  {
    path: URLS.GENERATE_INVOICE,
    name: 'Generate Invoice',
    Component: GenerateInvoicePage,
  },
  {
    path: URLS.PLANNER,
    name: 'Planner',
    Component: PlannerPage,
  },

  // Customer Area
  {
    path: URLS.CUSTOMER_AREA,
    name: 'Customer Area',
    Component: CustomerAreaEntryPage,
    waffle: 'CUSTOMER_AREA',
  },
  {
    path: URLS.CUSTOMER_AREA_BOOKINGS,
    name: 'Customer Booking List',
    Component: CustomerAreaBookingListPage,
    waffle: 'CUSTOMER_AREA',
  },
  {
    path: URLS.CUSTOMER_AREA_BOOKING,
    name: 'Customer Booking Details',
    Component: CustomerAreaBookingDetailsPage,
    waffle: 'CUSTOMER_AREA',
  },
  {
    path: URLS.CUSTOMER_AREA_DOCUMENTS,
    name: 'Customer Document List',
    Component: CustomerAreaDocumentListPage,
    waffle: 'CUSTOMER_AREA',
  },
] as Page[];
