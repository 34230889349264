import React, { FC } from 'react';
import { useAppType } from '@common/hooks';
import css from './styles.module.scss';

const Page: FC = ({ children }) => {
  const appType = useAppType();
  return <div className={`${css.page} ${css[appType]}`}>{children}</div>;
};

export default Page;
