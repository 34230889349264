// TO DO: Should be common component for DO and CA
import React, { FC } from 'react';
import { Modal, Table } from '@components';
import { ColumnType } from 'antd/lib/table';
import { format } from 'date-fns';
import { DEFAULT_USER_DATE_TIME_FORMAT } from '@common/constants';
import { StatusTransition } from '@common/interfaces';
import css from './styles.module.scss';

interface IStatusHistoryModal {
  closeModal: () => void;
  transitions: StatusTransition[];
}

const StatusHistoryModal: FC<IStatusHistoryModal> = ({ closeModal, transitions }) => {
  const columns: ColumnType<StatusTransition>[] = [
    {
      dataIndex: 'timestamp',
      key: 'timestamp',
      title: 'Date',
      render: (timestamp) => format(timestamp, DEFAULT_USER_DATE_TIME_FORMAT),
    },
    {
      dataIndex: 'to_state',
      key: 'to_state',
      title: 'Status',
      render: (status) => `Status change: ${status}`,
    },
    {
      dataIndex: 'user',
      key: 'user',
      title: 'User',
      render: (_, { user }) => user?.full_name || 'Automated',
    },
  ];

  return (
    <Modal title='Status history' closeModal={closeModal} loading={false}>
      <Modal.Content>
        <Table rowKey='timestamp' className={css.table} columns={columns} data={transitions} />
      </Modal.Content>
    </Modal>
  );
};

export default StatusHistoryModal;
