import React, { FC, useState } from 'react';
import { useFacilityContext, useGlobalContext, useRepository } from '@context';
import { Button, Calendar, EventBlock, Main, Page } from '@components';
import { ArrowLeftIcon, WarningIcon } from '@assets/svg';
import { useParams } from 'react-router';
import {
  IAppointment,
  IAppointmentType,
  IAppointmentURLType,
  IBookingDetails,
  PaginatedResponse,
} from '@common/interfaces';
import { useQuery } from 'react-query';
import { AppointmentForm } from './components';
import { mapAppointments } from '@components/EventBlock';
import { DEFAULT_SERVER_DATE_FORMAT, DEFAULT_USER_DATE_FORMAT, LINKS } from '@common/constants';
import { addDays, format, startOfDay, startOfWeek } from 'date-fns';
import { getDisabledDays } from '@common/utils';
import css from './styles.module.scss';

// Note: for now it's only week planner. Start date is Monday, end date is automatically sunday

const MAX_EVENTS_PER_WEEK = 100;

const BookingPlannerPage: FC = () => {
  const { facilityId, facility } = useFacilityContext();
  const { waffles } = useGlobalContext();
  const { id: bookingId = '', type } = useParams();
  const { appointmentRepository, bookingRepository } = useRepository();

  const isCheckOut = type === IAppointmentURLType.CHECK_OUT;
  const defaultStartPlannerDate = startOfWeek(startOfDay(new Date()), { weekStartsOn: 1 });

  const [startPlannerDate, setStartPlannerDate] = useState(defaultStartPlannerDate);

  const { data: bookingDetails, isLoading: isDetailsLoading } = useQuery<IBookingDetails>(
    `request-${bookingId}`,
    () => bookingRepository.getBookingDetails(facilityId, bookingId),
    {
      onSuccess: ({ appointments }) => {
        const currentAppointment = isCheckOut
          ? appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_OUT)
          : appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_IN);

        const monday = currentAppointment
          ? startOfWeek(startOfDay(new Date(currentAppointment.from_to_datetime_range.from)), { weekStartsOn: 1 })
          : startOfWeek(startOfDay(new Date()), { weekStartsOn: 1 });

        setStartPlannerDate(monday);
      },
    }
  );

  const { data: appointments, isLoading: isAppointmentsLoading } = useQuery<PaginatedResponse<IAppointment>>(
    ['appointments', startPlannerDate],
    () =>
      appointmentRepository.getAppointments(facilityId, {
        appointment_datetime_before: format(addDays(startPlannerDate, 6), DEFAULT_SERVER_DATE_FORMAT),
        appointment_datetime_after: format(startPlannerDate, DEFAULT_SERVER_DATE_FORMAT),
        limit: MAX_EVENTS_PER_WEEK,
      })
  );

  const title = isCheckOut ? 'Check-out planner' : 'Check-in planner / inspection';
  const events = mapAppointments(appointments?.results);
  const disabledDays = getDisabledDays(facility.work_time);

  const currentAppointment = bookingDetails
    ? isCheckOut
      ? bookingDetails.appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_OUT)
      : bookingDetails.appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_IN)
    : null;

  return (
    <Main loading={isDetailsLoading}>
      <Page>
        <div className={css.container}>
          <div className={css.content}>
            <Button
              text='Back to booking detail'
              variant='text'
              iconL={<ArrowLeftIcon />}
              link={LINKS.booking(bookingId)}
            />
            <div className={css.topLine}>
              <div className={css.title}>{title}</div>
              {waffles.flags.CUSTOMER_AREA.is_active && currentAppointment?.proposed_check_in_dates ? (
                <div className={css.box}>
                  <WarningIcon />
                  <div>
                    Dates proposed by customer:
                    <div className={css.dates}>
                      {currentAppointment.proposed_check_in_dates
                        .map((date) => format(date, DEFAULT_USER_DATE_FORMAT))
                        .join(', ')}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <Calendar
              disabledDays={disabledDays}
              endTime={facility.work_time?.end_time}
              eventTemplate={<EventBlock />}
              events={events}
              loading={isAppointmentsLoading}
              startPlannerDate={startPlannerDate}
              setStartPlannerDate={setStartPlannerDate}
              startTime={facility.work_time?.start_time}
            />
          </div>
          {bookingDetails ? (
            <div className={css.aside}>
              <AppointmentForm
                events={events}
                setStartPlannerDate={setStartPlannerDate}
                bookingDetails={bookingDetails}
              />
            </div>
          ) : null}
        </div>
      </Page>
    </Main>
  );
};

export default BookingPlannerPage;
