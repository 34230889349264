import React, { FC } from 'react';
import { Button, Dropdown, InformedField, Modal } from '@components';
import { ArrowRightIcon } from '@assets/svg';
import { Form, FormState } from 'informed';
import { useFacilityContext, useRepository } from '@context';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IBookingDetails, IDropdownOption, JobcardCreationData } from '@common/interfaces';
import { GroupBase, OptionsOrGroups } from 'react-select';
import css from './styles.module.scss';

interface ISelectFMSCustomerModal {
  closeModal: () => void;
  bookingDetails: IBookingDetails;
  refetch: () => void;
}

interface FormValues {
  fms_customer: IDropdownOption;
}

const yupSchema = Yup.object().shape({
  fms_customer: Yup.object().required('Required'),
});

const SelectFMSCustomerModal: FC<ISelectFMSCustomerModal> = ({ bookingDetails, closeModal, refetch }) => {
  const { facilityId } = useFacilityContext();
  const { bookingRepository, jobcardRepository } = useRepository();

  const { uuid: bookingId, asset_fms_customer } = bookingDetails;

  const { mutate: createJobcard, isLoading: inCreationJobcardProgress } = useMutation(
    (data: JobcardCreationData) => bookingRepository.createJobcard(facilityId, bookingId, data),
    {
      onSuccess: ({ jobcard }) => {
        toast.success(jobcard);
        refetch();
        closeModal();
      },
    }
  );

  const loadCustomerOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await jobcardRepository.getJobcardsCustomers(facilityId, {
      limit: 10,
      search,
      offset: loadedOptions.length,
    });

    return {
      options: results,
      hasMore: Boolean(next),
    };
  };

  const onSubmit = ({ values }: FormState) => {
    const { fms_customer } = values as unknown as FormValues;
    createJobcard({ fms_customer: fms_customer.value });
  };

  const initialValues = asset_fms_customer
    ? {
        fms_customer: { label: asset_fms_customer, value: asset_fms_customer },
      }
    : {};

  return (
    <Modal
      className={css.modal}
      title='Select FMS customer for jobcard creation'
      closeModal={closeModal}
      loading={inCreationJobcardProgress}
    >
      <Form onSubmit={onSubmit} yupSchema={yupSchema} initialValues={initialValues}>
        <Modal.Content>
          <div className={css.note}>Please select the customer who is related to the jobcard</div>
          <InformedField label='FMS Customer *'>
            <Dropdown
              isSearchable
              loadOptions={loadCustomerOptions}
              name='fms_customer'
              placeholder='Select customer'
            />
          </InformedField>
        </Modal.Content>
        <Modal.Footer>
          <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
          <Button text='Submit' iconR={<ArrowRightIcon />} type='submit' />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SelectFMSCustomerModal;
