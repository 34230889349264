import { FC } from 'react';
import { IBookingDetails, ProFormaInvoice } from '@common/interfaces';
import Box from '../Box';
import { DocDownloadIcon, RequestIcon } from '@assets/svg';
import { Button, Loader } from '@components';
import { useFacilityContext, useRepository } from '@context';

import { useMutation } from 'react-query';
import { useDownloadFile } from '@common/hooks';
import css from './styles.module.scss';

interface IDetailsBlock {
  bookingDetails: IBookingDetails;
  pfiData?: ProFormaInvoice;
}

interface IDetailsRow {
  label: string;
  value?: string | Array<string> | JSX.Element;
}

const DetailsRow: FC<IDetailsRow> = ({ label, value = '-' }) => {
  return (
    <div className={css.row}>
      <div className={css.label}>{label}</div>
      {Array.isArray(value) ? (
        <ol className={css.list}>
          {value.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ol>
      ) : (
        <div className={css.value}>{value}</div>
      )}
    </div>
  );
};

const DetailsBlock: FC<IDetailsBlock> = ({ bookingDetails, pfiData }) => {
  const { bookingRepository } = useRepository();
  const { facilityId } = useFacilityContext();

  const {
    collector_details,
    current_odometer_value,
    defects_rejection_remarks,
    has_spare_parts,
    job_reason,
    known_issues,
    make,
    manager_details,
    model,
    odometer_metric,
    plate_number,
    remarks,
    request_number,
    requestor_details,
    uuid: bookingId,
  } = bookingDetails;

  const { mutate: getSummaryPdf, isLoading: inProgress } = useMutation(
    'summary-pdf',
    () => bookingRepository.getBookingSummary(facilityId, bookingId),
    {
      onSuccess: (data) => useDownloadFile(data, `${request_number}-summary.pdf`),
    }
  );

  return (
    <Box
      title='Request summary'
      icon={<RequestIcon />}
      buttons={
        <>
          <Button text='Request summary' iconR={<DocDownloadIcon />} variant='text' onClick={() => getSummaryPdf()} />
          {pfiData?.pdf ? <Button text='PFI' iconR={<DocDownloadIcon />} variant='text' link={pfiData.pdf} /> : null}
        </>
      }
    >
      <Loader loading={inProgress}>
        <div className={css.container}>
          <div className={css.box}>
            <div className={css.sub}>
              <div className={css.title}>Requestor contacts</div>
              <DetailsRow label='Email' value={requestor_details.email} />
              <DetailsRow label='Phone number' value={requestor_details.phone_number} />
            </div>
            <div className={css.sub}>
              <div className={css.title}>Asset details</div>
              <DetailsRow label='Make' value={make?.label} />
              <DetailsRow label='Model' value={model?.label} />
              <DetailsRow label='Plate/ID' value={plate_number} />
              <DetailsRow
                label='Current odometer'
                value={`${current_odometer_value.toLocaleString('en')} ${odometer_metric}`}
              />
            </div>
            <div className={css.sub}>
              <div className={css.title}>Authorizing staff</div>
              <DetailsRow
                label='Authorizing manager details'
                value={
                  <>
                    {`${manager_details.name} ${manager_details.surname}`}
                    <br />
                    {manager_details.email}
                  </>
                }
              />
              {collector_details ? (
                <DetailsRow
                  label='Person authorized to deliver/collect the asset'
                  value={
                    <>
                      {collector_details.name} {collector_details.surname}
                      <br />
                      {collector_details.email} {collector_details.phone_number}
                    </>
                  }
                />
              ) : null}
            </div>
          </div>
          <div className={css.box}>
            <div className={css.sub}>
              <div className={css.title}>Intervention details</div>
              <DetailsRow label='Spare parts provided by client' value={has_spare_parts ? 'Yes' : 'No'} />
              <DetailsRow
                label='Defects'
                value={known_issues.map(({ text, type_of_repair }) => `${text} / ${type_of_repair}`)}
              />
              {defects_rejection_remarks ? (
                <DetailsRow label='Customer comment on new defects' value={defects_rejection_remarks} />
              ) : null}
              <DetailsRow label='Job reason' value={job_reason} />
              <DetailsRow label='Remarks' value={remarks} />
            </div>
          </div>
        </div>
      </Loader>
    </Box>
  );
};

export default DetailsBlock;
