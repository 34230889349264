import axios, { AxiosInstance } from 'axios';

export const decoupledClientInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}`,
  headers: { 'Content-Type': 'application/json' },
});

export const cmsClientInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CMS_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const monolithClientInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HBH_API,
  headers: {
    'Content-Type': 'application/json',
  },
});
