import {
  DefaultKnownIssue,
  DefaultListParams,
  IBookingDetails,
  IBookingStatus,
  IComment,
  KnownIssue,
  ServerItem,
  WorkSchedule,
} from '@common/interfaces';
import { DataItem, Nullable } from '@common/interfaces';

export enum ICustomerBookingListTab {
  TO_PROCESS = 'TO_PROCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export interface PendingAction {
  uuid: string;
  request_number: string;
  pending_date: string;
}

export interface PendingActions {
  check_in_date_to_accept: PendingAction[];
  known_issues_to_review: PendingAction[];
  pfi_to_approve: PendingAction[];
}

export interface ICustomerBookingListParams extends DefaultListParams {
  created_after?: string;
  created_before?: string;
  facilities?: string;
  pending_actions?: string;
  requestor?: string;
  state?: string;
  tab_type?: string;
}

export interface ICustomerBookingFiltersParams {
  tab_type: ICustomerBookingListTab;
}

export interface ICustomerBookingFilters {
  states: DataItem[];
  requesters: DataItem[];
  facilities: DataItem[];
}

export interface IWorkshop {
  uuid: string;
  city_name: string;
  country_name: string;
  facility_id_on_hbh: number;
}

export interface BookingPendingActions {
  approve_pfi: boolean;
  confirm_check_in_date: boolean;
  review_known_issues: boolean;
}

export interface ICustomerBooking {
  comments: number;
  created: string;
  facility: IWorkshop;
  jobcard: Nullable<string>;
  last_comment: Nullable<IComment>;
  make: string;
  model: string;
  pending_actions: BookingPendingActions;
  plate_number: string;
  request_number: string;
  requestor_name: string;
  requestor_surname: string;
  state: IBookingStatus;
  uuid: string;
}

export interface ICustomerBookingDetails extends IBookingDetails {
  check_in_date: Nullable<string>;
  check_out_date: Nullable<string>;
  facility_work_time: Nullable<WorkSchedule>;
  feedback_created: boolean;
  labour_cost: Nullable<number>;
  parts_cost: Nullable<number>;
  pending_actions: BookingPendingActions;
}

export interface ICustomerDocumentsListParams extends DefaultListParams {
  generated_time_after?: string;
  generated_time_before?: string;
}

export interface ICustomerInvoice {
  country: string;
  generated_time: string;
  grand_total: 4180;
  invoice_number: string;
  job_number: string;
  make: string;
  model: string;
  pdf: string;
  plate: string;
  total_cost: 4000;
  uuid: string;
  workshop: string;
}

export interface ICustomerStatement {
  country: string;
  generated_time: string;
  grand_total: number;
  invoice_number: string;
  number_of_jobcards: number;
  pdf: string;
  reference_period: string;
  total_cost: number;
  uuid: string;
  workshop: string;
}
export interface IAppointmentAcceptData {
  proposed_check_in_dates: string[];
  accepted: boolean;
}

// Getting defects
export interface IDefects {
  known_issues: KnownIssue[];
  new_defects_remarks: string;
}

// Adding defects
export interface IAddDefectsData {
  known_issues: DefaultKnownIssue[];
  new_defects_remarks: string;
}

// Confirming defects
export interface IConfirmDefectsData {
  known_issues: KnownIssue[];
  defects_rejection_remarks?: string;
}

export interface IBookingOptions {
  type_of_repair: ServerItem[];
}

export interface FeedbackQuestion {
  uuid: string;
  text: string;
  need_rating: boolean;
  need_comment: boolean;
  required: boolean;
}

export interface FeedbackAnswer {
  question: string;
  rating?: number;
  comment?: string;
}

export interface FeedbackData {
  answers: FeedbackAnswer[];
  booking: string;
}
