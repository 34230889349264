import type { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';
import Toast from '../Toast';
import { Loader } from '@components';
import css from './styles.module.scss';

interface MainProps {
  background?: 'office' | 'customer' | 'home' | 'none';
  children: ReactNode;
  className?: string;
  loading?: boolean;
  withoutPaddings?: boolean;
  withoutAuth?: boolean;
}

const Main = ({
  children,
  className = '',
  withoutPaddings = false,
  withoutAuth = false,
  loading = false,
  background = 'office',
}: MainProps) => {
  return (
    <Loader loading={loading}>
      <main className={`${css.main} ${css[background]} ${className} ${withoutAuth ? css.withoutAuth : ''}`}>
        <div className={`${css.container} ${withoutPaddings ? css.withoutPaddings : ''}`}>{children}</div>
        <Toast />
        <Tooltip id='global-tooltip' className={css.tooltip} clickable />
      </main>
    </Loader>
  );
};

export default Main;
