import { FC, useState } from 'react';
import { Button, Chat, Main, Page, CustomerStatusDropdown } from '@components';
import { v4 as uuid } from 'uuid';
import { ArrowLeftIcon, CheckInIcon, CheckOutIcon, CommentIcon } from '@assets/svg';
import {
  IAppointmentType,
  IComment,
  ICommentDraft,
  ICustomerBookingDetails,
  IDefects,
  Nullable,
  PaginatedResponse,
  ProFormaInvoice,
} from '@common/interfaces';
import { useMutation, useQuery } from 'react-query';
import { useRepository } from '@context';
import { useParams } from 'react-router-dom';
import { ActionsBlock, DetailsBlock, FinancialBlock, StatusHistoryModal } from './components';
import { toast } from 'react-toastify';
import Box from './components/Box';
import { getLocalDate } from '@common/utils';
import { DEFAULT_USER_DATE_TIME_FORMAT, URLS } from '@common/constants';
import { format } from 'date-fns';
import css from './styles.module.scss';
import { ChatType } from '@components/Chat';

const formatDate = (date: Nullable<string>) => {
  if (!date) return '---';
  return format(getLocalDate(date), DEFAULT_USER_DATE_TIME_FORMAT);
};

const CustomerAreaBookingDetailsPage: FC = () => {
  const { id: bookingId = '' } = useParams();
  const { customerRepository } = useRepository();

  const [statusHistoryModalOpen, setStatusHistoryModalOpen] = useState(false);

  const {
    data: bookingDetails,
    isLoading: isDetailsLoading,
    refetch,
  } = useQuery<ICustomerBookingDetails>(`request-${bookingId}`, () => customerRepository.getBookingDetails(bookingId));

  const { data: pfiData, isLoading: isPfiLoading } = useQuery<ProFormaInvoice>(
    `pfi-${bookingDetails?.pfi}`,
    () => customerRepository.getPfi(bookingDetails?.pfi || ''),
    {
      enabled: Boolean(bookingDetails?.pfi),
    }
  );

  const {
    data: defects,
    isLoading: isDefectsLoading,
    refetch: refetchDefects,
  } = useQuery<IDefects>(`${bookingId}-defects`, () => customerRepository.getBookingDefects(bookingId));

  const {
    data: comments,
    isLoading: isCommentsLoading,
    refetch: refetchComments,
  } = useQuery<PaginatedResponse<IComment>>('comments', () => customerRepository.getComments(bookingId));

  const { mutate: sendComment, isLoading: isSendingCommentInProgress } = useMutation(
    (data: ICommentDraft) => customerRepository.sendComment(bookingId, data),
    {
      onSuccess: () => {
        toast.success('Comment was added successfully');
        refetchComments();
      },
    }
  );

  const checkInDate = bookingDetails?.appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_IN)
    ?.from_to_datetime_range.from;

  const defaultComments = checkInDate
    ? [
        {
          body: `The asset check-in is scheduled on ${format(
            getLocalDate(checkInDate),
            DEFAULT_USER_DATE_TIME_FORMAT
          )}`,
          uuid: uuid(),
          author: 'System',
          label: 'S',
        },
      ]
    : undefined;

  const isLoading =
    isCommentsLoading || isDetailsLoading || isDefectsLoading || isSendingCommentInProgress || isPfiLoading;

  return (
    <Main loading={isLoading} background='customer'>
      <Page>
        {bookingDetails ? (
          <>
            <div className={css.top}>
              <div>
                <Button
                  text='Back to requests'
                  variant='text'
                  iconL={<ArrowLeftIcon />}
                  link={URLS.CUSTOMER_AREA_BOOKINGS}
                />
                <div className={css.titleLine}>
                  <div className={css.title}>
                    Request <div className={css.number}>{bookingDetails.request_number}</div>
                  </div>
                  <CustomerStatusDropdown status={bookingDetails.state} />
                  {bookingDetails.transitions.length > 0 ? (
                    <Button text='View change history' variant='text' onClick={() => setStatusHistoryModalOpen(true)} />
                  ) : null}
                </div>
                <div className={css.info}>
                  <div>
                    <CheckInIcon />
                    Check-in: {formatDate(bookingDetails.check_in_date)}
                  </div>
                  <div>
                    <CheckOutIcon />
                    Check-out: {formatDate(bookingDetails.check_out_date)}
                  </div>
                  <div>Jobcard: {bookingDetails.jobcard?.job_number || '---'}</div>
                </div>
              </div>
              <ActionsBlock
                refetchDefects={refetchDefects}
                defects={defects}
                bookingDetails={bookingDetails}
                refetch={refetch}
                pfiData={pfiData}
              />
            </div>
            <section className={css.container}>
              <div className={css.leftContent}>
                <DetailsBlock bookingDetails={bookingDetails} pfiData={pfiData} />
              </div>
              <div className={css.rightContent}>
                <Box className={css.chat} title='Comments' icon={<CommentIcon />}>
                  <Chat
                    defaultComments={defaultComments}
                    comments={comments?.results || []}
                    sendComment={(comment) => sendComment(comment)}
                    placeholder='Send a message to the workshop...'
                    type={ChatType.CUSTOMER}
                  />
                </Box>
                <FinancialBlock bookingDetails={bookingDetails} />
              </div>
            </section>
          </>
        ) : null}
        {statusHistoryModalOpen && bookingDetails && (
          <StatusHistoryModal
            transitions={bookingDetails.transitions}
            closeModal={() => setStatusHistoryModalOpen(false)}
          />
        )}
      </Page>
    </Main>
  );
};

export default CustomerAreaBookingDetailsPage;
