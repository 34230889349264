import React, { Dispatch, FC, SetStateAction } from 'react';
import { useFieldApi, Multistep } from 'informed';
import { BookingFormOptions } from '@common/interfaces';
import { IYupSchema, STEPS, AssetStepValues } from '../../consts';
import InformedField from '../InformedField';
import Subtitle from '../Subtitle';
import Navigation from '../Navigation';
import comCss from '../../styles.module.scss';
import { ProjectIcon } from '@assets/svg';
import RadioOptionField from '../RadioOptionField';
import FreeOptionsField from '../FreeOptionsField';
import { Dropdown, NumberField } from '@components';
import { useRepository } from '@context';
import { useParams } from 'react-router-dom';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { mapPlateNumbers } from '@common/utils';
import { IAssetDropdownOption } from '@common/utils/mapPlateNumbers';

interface AssetInformationStepProps {
  formOptions: BookingFormOptions;
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
  countryCode?: string;
}

const defaultOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

const AssetInformationStep: FC<AssetInformationStepProps> = ({ countryCode, formOptions, setYupSchema }) => {
  const { id: facilityId = '' } = useParams<{ id: string }>();
  const { bookingRepository } = useRepository();

  const { setValue, getValue } = useFieldApi(STEPS.ASSET);
  const stepValues = (getValue() || {}) as Partial<AssetStepValues>;

  const { type_of_asset, make } = stepValues;
  const makeValue = make ? make.value : '';
  const typeRepairOptions = formOptions.type_of_repair[type_of_asset?.value || 0] || [];

  const loadAssetOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await bookingRepository.getAssets({
      limit: 10,
      search,
      offset: loadedOptions.length,
      by_country: countryCode,
    });

    return {
      options: mapPlateNumbers(results),
      hasMore: Boolean(next),
    };
  };

  const loadMakeOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await bookingRepository.getMakes(facilityId, {
      limit: 10,
      search,
      offset: loadedOptions.length,
    });

    return {
      options: results,
      hasMore: Boolean(next),
    };
  };

  const loadModelOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await bookingRepository.getModels(facilityId, {
      limit: 10,
      search,
      offset: loadedOptions.length,
      make: makeValue,
    });

    return {
      options: results,
      hasMore: Boolean(next),
    };
  };

  const handleSearchPlateNumber = async ({
    make,
    model,
    year,
    odometer_value,
    odometer_metric,
  }: IAssetDropdownOption) => {
    setValue({
      ...stepValues,
      make,
      model,
      year_of_asset: year,
      current_odometer_value: odometer_value,
      odometer_metric: formOptions.odometer_types.find(({ value }) => value === odometer_metric),
    });
  };

  const handleCreateMake = async (value: string) => {
    const option = await bookingRepository.createMake(facilityId, value);
    setValue({ ...stepValues, make: option });
  };

  const handleCreateModel = async (value: string) => {
    const option = await bookingRepository.createModel(facilityId, value, makeValue);
    setValue({ ...stepValues, model: option });
  };

  const resetModel = () => {
    setValue({ ...stepValues, model: undefined });
  };

  const resetTypesRepair = () => {
    setValue({
      ...stepValues,
      known_issues: stepValues.known_issues?.map(({ ...values }) => ({
        ...values,
        type_of_repair: '',
      })),
    });
  };

  return (
    <Multistep.Step step={STEPS.ASSET}>
      <Subtitle text='Asset information' icon={<ProjectIcon />} />

      <div className={comCss.row}>
        <InformedField label='Plate, Boat or Generator number *'>
          <Dropdown
            loadOptions={loadAssetOptions}
            name='plate_number'
            isSearchable
            isCreatable
            placeholder='Search...'
            onChange={handleSearchPlateNumber}
          />
        </InformedField>
        <InformedField label='Job reason *'>
          <Dropdown name='job_reason' options={formOptions.job_reasons} />
        </InformedField>
      </div>

      <InformedField label='Asset type *'>
        <RadioOptionField name='type_of_asset' options={formOptions.type_of_asset} isRow onChange={resetTypesRepair} />
      </InformedField>

      <div className={comCss.row}>
        <InformedField label='Make *'>
          <Dropdown
            loadOptions={loadMakeOptions}
            onCreateOption={handleCreateMake}
            name='make'
            isSearchable
            placeholder='Search...'
            isCreatable
            onChange={resetModel}
          />
        </InformedField>
        <InformedField label='Model *'>
          <Dropdown
            loadOptions={loadModelOptions}
            onCreateOption={handleCreateModel}
            name='model'
            isSearchable
            placeholder='Search...'
            isCreatable
            isDisabled={!make}
            noOptionsMessage={() => 'Write the model name'}
          />
        </InformedField>
      </div>

      <InformedField label='Year of the asset'>
        <NumberField name='year_of_asset' skipFormatting />
      </InformedField>

      <div className={comCss.row}>
        <InformedField label='Current odometer value *'>
          <NumberField name='current_odometer_value' />
        </InformedField>
        <InformedField label='Odometer type *'>
          <Dropdown name='odometer_metric' options={formOptions.odometer_types} />
        </InformedField>
      </div>

      <InformedField label='Are you bringing the needed spare parts to perform the service? *'>
        <RadioOptionField name='has_spare_parts' options={defaultOptions} />
      </InformedField>

      <InformedField label='Is a quotation required before the job start? *'>
        <RadioOptionField name='is_quotation_required' options={defaultOptions} />
      </InformedField>

      <InformedField label='Known fault to be checked *'>
        <FreeOptionsField name='known_issues' options={typeRepairOptions} disabledOptions={!type_of_asset} />
      </InformedField>

      <Navigation setYupSchema={setYupSchema} />
    </Multistep.Step>
  );
};

export default AssetInformationStep;
