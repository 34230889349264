import React, { FC } from 'react';
import { Button, Modal } from '@components';
import css from './styles.module.scss';

interface ISendPfiModal {
  closeModal: () => void;
  sendPfiDocument: () => void;
}

const SendPfiModal: FC<ISendPfiModal> = ({ sendPfiDocument, closeModal }) => {
  return (
    <Modal title='Confirm sending PFI' closeModal={closeModal} className={css.modal}>
      <Modal.Content>Are you sure you want to send the proforma invoice (PFI) to the customer?</Modal.Content>
      <Modal.Footer>
        <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
        <Button
          text='Send PFI'
          onClick={() => {
            closeModal();
            sendPfiDocument();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SendPfiModal;
