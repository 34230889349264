import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ColumnType } from 'antd/lib/table/interface';
import {
  Nullable,
  PaginatedResponse,
  DateRange,
  SessionStorageKey,
  ICustomerInvoice,
  ICustomerStatement,
} from '@common/interfaces';
import { useGlobalContext, useRepository } from '@context';
import { Button, Main, MonthPicker, Page, SearchField, Table, TablePagination, Tabs } from '@components';
import { DocDownloadIcon, MarkerIcon } from '@assets/svg';
import { DEFAULT_SERVER_DATE_FORMAT, DEFAULT_USER_DATE_FORMAT, DEFAULT_USER_MONTH_FORMAT } from '@common/constants';
import { format } from 'date-fns';
import { formatToMoneyString } from '@common/utils';
import css from './styles.module.scss';

enum IStatementListTab {
  STATEMENTS = 'STATEMENTS',
  INVOICES = 'INVOICES',
}

const tabList = [
  { name: 'Invoices', key: IStatementListTab.INVOICES },
  { name: 'Statements', key: IStatementListTab.STATEMENTS },
];

const DEFAULT_PAGE_SIZE = 10;

const CustomerAreaDocumentListPage: FC = () => {
  const {
    user: { customer_area_location, organisation },
  } = useGlobalContext();

  const { customerRepository } = useRepository();
  const params = JSON.parse(sessionStorage.getItem(SessionStorageKey.CUSTOMER_DOCUMENTS_LIST_PARAMS) || '{}') || {};

  const [tab, setTab] = useState<IStatementListTab>((params.tab as IStatementListTab) ?? tabList[0].key);
  const [page, setPage] = useState(params.page || 1);
  const [pageSize, setPageSize] = useState(params.pageSize || DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState(params.order || '');
  const [search, setSearch] = useState('');
  const [dateFilter, setDateFilter] = useState<Nullable<DateRange>>(params.dateFilter || null);

  const isStatement = tab === IStatementListTab.STATEMENTS;
  const isInvoice = tab === IStatementListTab.INVOICES;

  const getListRequest = isStatement
    ? () =>
        customerRepository.getStatements({
          limit: pageSize,
          offset: (page - 1) * 10,
          ordering: order,
          search,
          generated_time_after: dateFilter?.created_after,
          generated_time_before: dateFilter?.created_before,
        })
    : () =>
        customerRepository.getInvoices({
          limit: pageSize,
          offset: (page - 1) * 10,
          ordering: order,
          search,
          generated_time_after: dateFilter?.created_after,
          generated_time_before: dateFilter?.created_before,
        });

  const { data: documents, isLoading: isDataLoading } = useQuery<
    PaginatedResponse<ICustomerStatement | ICustomerInvoice>
  >(['documents', tab, order, page, pageSize, dateFilter, search], getListRequest);

  const columns: ColumnType<ICustomerStatement | ICustomerInvoice>[] = [
    {
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      title: 'Invoice #',
    },
    {
      dataIndex: 'generated_time',
      key: 'generated_time',
      title: 'Generated at',
      render: (generated_time) => (generated_time ? format(new Date(generated_time), DEFAULT_USER_DATE_FORMAT) : '-'),
    },
    ...(isInvoice
      ? ([
          {
            dataIndex: 'job_number',
            key: 'job_number',
            title: 'Jobcard #',
          },
          {
            dataIndex: 'plate',
            key: 'plate',
            title: 'Plate ID',
          },
          {
            dataIndex: 'asset',
            key: 'asset',
            title: 'Asset',
            render: (_, { make, model }) => `${make} ${model}`,
          },
          {
            dataIndex: 'workshop',
            key: 'workshop',
            title: 'Workshop',
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as ColumnType<any>[])
      : [
          {
            dataIndex: 'reference_period',
            key: 'reference_period',
            title: 'Reference period',
            render: (reference_period: string) => format(new Date(reference_period), DEFAULT_USER_MONTH_FORMAT),
          },
          {
            dataIndex: 'workshop',
            key: 'workshop',
            title: 'Workshop',
          },
          {
            dataIndex: 'number_of_jobcards',
            key: 'number_of_jobcards',
            title: 'No of jobcards',
          },
        ]),
    {
      dataIndex: 'grand_total',
      key: 'grand_total',
      title: 'Total cost',
      render: (grand_total) => formatToMoneyString(grand_total),
    },
    {
      dataIndex: 'pdf',
      key: 'pdf',
      title: 'Actions',
      render: (pdf) => (
        <Button text='PDF' iconR={<DocDownloadIcon />} variant='text' link={pdf} disabled={!pdf} target='_blank' />
      ),
    },
  ];

  const clearPagination = () => {
    setPage(1);
    setPageSize(DEFAULT_PAGE_SIZE);
    setOrder('');
  };

  const clearFilters = () => {
    setDateFilter(null);
  };

  const clearAllParams = () => {
    clearPagination();
    clearFilters();
    setSearch('');
  };

  const tabChange = (tab: string) => {
    setTab(tab as IStatementListTab);
    clearAllParams();
  };

  useEffect(() => {
    sessionStorage.setItem(
      SessionStorageKey.CUSTOMER_DOCUMENTS_LIST_PARAMS,
      JSON.stringify({
        tab,
        order,
        limit: pageSize,
        offset: (page - 1) * 10,
        dateFilter,
      })
    );
  }, [tab, order, page, pageSize, dateFilter]);

  const isFiltered = dateFilter;

  return (
    <Main background='customer'>
      <Page>
        <div className={css.top}>
          <div>
            <div className={css.title}>Invoices and statements</div>
            <div className={css.info}>
              <MarkerIcon />
              {customer_area_location
                ? `${organisation?.short_name || ''} ${customer_area_location.country_name || ''}${
                    customer_area_location.city_name ? ', ' + customer_area_location.city_name : ''
                  }`
                : null}
            </div>
          </div>
        </div>
        <Tabs tabList={tabList} tabKey={tab} onTabChange={tabChange} />
        <section className={css.tab}>
          <div className={css.topPanel}>
            <div className={css.filters}>
              <span>Filters:</span>
              <MonthPicker
                showDates
                triggerText='Date of generation'
                initialFrom={dateFilter?.created_after ? new Date(dateFilter.created_after) : null}
                initialTo={dateFilter?.created_before ? new Date(dateFilter.created_before) : null}
                setMonths={(from, to) => {
                  if (from && to) {
                    setDateFilter({
                      created_after: format(from, DEFAULT_SERVER_DATE_FORMAT),
                      created_before: format(to, DEFAULT_SERVER_DATE_FORMAT),
                    });
                  }
                }}
              />
              {isFiltered ? <Button text='Reset filters' variant='text' onClick={() => clearAllParams()} /> : null}
            </div>
            <SearchField
              className={css.search}
              onChange={setSearch}
              value={search}
              placeholder='Search invoice or statement...'
            />
          </div>
          <Table
            className={css.table}
            columns={columns}
            data={documents?.results}
            loading={isDataLoading}
            onChangeColumnOrder={setOrder}
          />
          <TablePagination
            page={page}
            onChangePage={setPage}
            totalCount={documents?.count}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
          />
        </section>
      </Page>
    </Main>
  );
};

export default CustomerAreaDocumentListPage;
