import { FC } from 'react';
import { Button, InformedField, Main, TextField } from '@components';
import * as Yup from 'yup';
import { Form, FormState } from 'informed';
import { LoginIcon, LogoIcon } from '@assets/svg';
import { useAuth } from '@unbooking/ui-auth';
import { toast } from 'react-toastify';
import css from './styles.module.scss';

interface FormValues {
  email: string;
}

const yupSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
});

const LoginPage: FC = () => {
  const { authorize } = useAuth();

  const onSubmit = async (formData: FormState) => {
    const { email } = formData.values as unknown as FormValues;

    try {
      await authorize(email);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <Main loading={false} withoutPaddings background='home' className={css.container}>
      <div className={css.form}>
        <div className={css.title}>
          <LogoIcon />
          <div>
            WORKSHOPS <br />
            <span>SERVICES</span>
          </div>
        </div>
        <div className={css.subtitle}>
          Log in to <span>Workshop</span>
        </div>
        <Form onSubmit={onSubmit} yupSchema={yupSchema} initialValues={{ email: '' }}>
          <InformedField label='Work email *' className={css.row}>
            <TextField name='email' placeholder='name@example.com' />
          </InformedField>
          <div className={css.registration}>
            New user? <a href={`${process.env.REACT_APP_HBH_URL}/register/`}>Create your account</a>
          </div>
          <Button variant='supernova' text='Login' iconR={<LoginIcon />} type='submit' className={css.button} />
        </Form>
      </div>
    </Main>
  );
};

export default LoginPage;
