import { IAppointmentURLType } from '@common/interfaces';

export enum AppType {
  CUSTOMER_AREA = 'ca',
  DEFAULT = 'default',
  DIGITAL_OFFICE = 'do',
}

// Warning: we use these base urls for getting app type by location.pathname (useAppType)
const DIGITAL_OFFICE_BASE = '/digital-office'; // AppType.DIGITAL_OFFICE
const CUSTOMER_AREA_BASE = '/customer-area'; // AppType.CUSTOMER_AREA

export const URLS = {
  // AppType.DEFAULT / Base
  ROOT: '/',
  NOT_FOUND: '/not-found',
  FORBIDDEN: '/forbidden',

  // AppType.DEFAULT / Platform
  BOOK_MAINTENANCE: '/book-maintenance',
  BOOK_MAINTENANCE_FORM: '/book-maintenance/:id',
  BOOK_MAINTENANCE_SUCCESS: '/book-maintenance/success',

  // AppType.DIGITAL_OFFICE
  ANALYTICS: `${DIGITAL_OFFICE_BASE}/analytics`,
  BOOKING: `${DIGITAL_OFFICE_BASE}/bookings/:id`,
  BOOKINGS: `${DIGITAL_OFFICE_BASE}/bookings`,
  BOOKING_PLANNER: `${DIGITAL_OFFICE_BASE}/bookings/:id/planner/:type`,
  DO: DIGITAL_OFFICE_BASE,
  DOCUMENTS: `${DIGITAL_OFFICE_BASE}/documents`,
  GENERATE_INVOICE: `${DIGITAL_OFFICE_BASE}/documents/new/invoice`,
  GENERATE_PFI: `${DIGITAL_OFFICE_BASE}/bookings/:id/pfi/:pfiId`,
  GENERATE_STATEMENT: `${DIGITAL_OFFICE_BASE}/documents/new/statement`,
  PLANNER: `${DIGITAL_OFFICE_BASE}/planner`,

  // AppType.CUSTOMER_AREA
  CUSTOMER_AREA: CUSTOMER_AREA_BASE,
  CUSTOMER_AREA_BOOKING: `${CUSTOMER_AREA_BASE}/bookings/:id`,
  CUSTOMER_AREA_BOOKINGS: `${CUSTOMER_AREA_BASE}/bookings`,
  CUSTOMER_AREA_DOCUMENTS: `${CUSTOMER_AREA_BASE}/documents`,
};

// TO DO: URLS/Links merging and refactoring
export const LINKS = {
  booking: (bookingId: string) => `${DIGITAL_OFFICE_BASE}/bookings/${bookingId}/`,
  pfi: (bookingId: string, pfiId: string) => `${DIGITAL_OFFICE_BASE}/bookings/${bookingId}/pfi/${pfiId}/`,
  plannerCheckIn: (bookingId: string) =>
    `${DIGITAL_OFFICE_BASE}/bookings/${bookingId}/planner/${IAppointmentURLType.CHECK_IN}`,
  plannerCheckOut: (bookingId: string) =>
    `${DIGITAL_OFFICE_BASE}/bookings/${bookingId}/planner/${IAppointmentURLType.CHECK_OUT}`,

  customerBooking: (bookingId: string) => `${CUSTOMER_AREA_BASE}/bookings/${bookingId}/`,
};

export const DEFAULT_START_TIME = '09:00:00';
export const DEFAULT_END_TIME = '18:00:00';

export const DEFAULT_TIME_INTERVAL = 60; // react-datepicker
export const DEFAULT_DATE_PICKER_FORMAT = 'dd/MM/yyyy'; // react-datepicker

export const DEFAULT_SERVER_DATE_FORMAT = 'yyyy-MM-dd'; // date-fns
export const DEFAULT_SERVER_MONTH_FORMAT = 'yyyy-MM'; // date-fns
export const DEFAULT_SERVER_TIME_FORMAT = 'HH:mm:ss'; // date-fns
export const DEFAULT_USER_DATE_FORMAT = 'd MMM yyyy'; // date-fns
export const DEFAULT_USER_MONTH_FORMAT = 'MMMM yyyy'; // date-fns
export const DEFAULT_USER_DATE_TIME_FORMAT = 'd MMMM yyyy, HH:mm'; // date-fns
export const DEFAULT_MONTH_PICKER_FORMAT = 'MMM/yyyy'; // date-fns

export const MIN_ASSET_YEAR = 1900;
export const MAX_ASSET_YEAR = new Date().getFullYear();
export const MAX_DEFECTS_COUNT = 10;

export const DEFAULT_CHART_COLORS = [
  '#009EDB',
  '#5ED8AE',
  '#D5BF53',
  '#FDA2CA',
  '#50D5EB',
  '#F7B825',
  '#FF707C',
  '#89EAA8',
  '#FDA987',
];
