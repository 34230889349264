import React, { FC } from 'react';
import { Button, InformedField, Modal, TextAreaField, Tip } from '@components';
import { Form, FormState } from 'informed';
import * as Yup from 'yup';
import { SaveIcon } from '@assets/svg';
import { BookingFormOptions, IAddDefectsData, IDefects, ITypeOfAsset } from '@common/interfaces';
import { useFacilityContext, useRepository } from '@context';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import FreeOptionsField from '../FreeOptionsField';
import { MAX_DEFECTS_COUNT } from '@common/constants';
import css from './styles.module.scss';

interface IAddDefectsModal {
  assetType: ITypeOfAsset;
  bookingId: string;
  closeModal: () => void;
  defects: IDefects;
  refetchDefects: () => void;
}

type DefectsFormValues = IAddDefectsData;

const YupIssueType = () =>
  Yup.object().shape({
    text: Yup.string().required('Text is required'),
    type_of_repair: Yup.string().required('Type of repair is required'),
  });

const yupSchema = Yup.object().shape({
  new_defects_remarks: Yup.string(),
  known_issues: Yup.array()
    .of(YupIssueType())
    .min(1, 'At least one known issue is required')
    .required('At least one known issue is required'),
});

const AddDefectsModal: FC<IAddDefectsModal> = ({ assetType, bookingId, closeModal, defects, refetchDefects }) => {
  const { facilityId } = useFacilityContext();
  const { bookingRepository } = useRepository();

  const { data: options, isLoading: isOptionsLoading } = useQuery<BookingFormOptions>(`${bookingId}-options`, () =>
    bookingRepository.getBookingFormOptions(facilityId)
  );

  const { mutate: addDefects, isLoading: inAddingDefectsProgress } = useMutation(
    (data: IAddDefectsData) => bookingRepository.addDefects(facilityId, bookingId, data),
    {
      onSuccess: () => {
        refetchDefects();
        toast.success('Defects added successfully');
        closeModal();
      },
    }
  );

  const onSubmit = ({ values }: FormState) => {
    const formData = values as unknown as DefectsFormValues;
    addDefects(formData);
  };

  const initialValues = {
    new_defects_remarks: defects?.new_defects_remarks,
    known_issues: [],
  };

  const typeOptions = !options ? [] : options.type_of_repair[assetType];
  const currentDefects = defects?.known_issues.filter(({ accepted }) => accepted !== false) || []; // Note: except rejected defects
  const isFullDefectList = currentDefects.length >= MAX_DEFECTS_COUNT;

  const isLoading = inAddingDefectsProgress || isOptionsLoading;

  return (
    <Modal loading={isLoading} title='Report new defects' closeModal={closeModal} className={css.modal}>
      {defects ? (
        <Form key={Date.now()} onSubmit={onSubmit} yupSchema={yupSchema} initialValues={initialValues}>
          <Modal.Content>
            <div className={css.box}>
              {currentDefects.map(({ text, need_to_accept, type_of_repair, from_do }, idx) => {
                return (
                  <div className={css.defectRow} key={idx}>
                    <span>{`${idx + 1}. ${text} / ${type_of_repair}`}</span>
                    {need_to_accept ? <span className={css.approval}>Waiting for approval</span> : null}
                    {!from_do ? <span className={css.fromBooking}>Requested by customer</span> : null}
                  </div>
                );
              })}
            </div>
            <InformedField label='Defects *' className={css.box}>
              <FreeOptionsField name='known_issues' options={typeOptions} currentCount={currentDefects.length} />
            </InformedField>
            <InformedField label='Remarks'>
              <TextAreaField name='new_defects_remarks' disabled={isFullDefectList} />
            </InformedField>
          </Modal.Content>
          <Modal.Footer>
            <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
            <Tip isVisible={isFullDefectList} text='You can add no more than 10 faults'>
              <Button text='Save' iconR={<SaveIcon />} type='submit' disabled={isFullDefectList} />
            </Tip>
          </Modal.Footer>
        </Form>
      ) : null}
    </Modal>
  );
};

export default AddDefectsModal;
