import { FC } from 'react';
import { ICustomerBookingDetails, Nullable } from '@common/interfaces';
import Box from '../Box';
import { formatToMoneyString } from '@common/utils';
import { DocDownloadIcon, StatementIcon } from '@assets/svg';
import { Button } from '@components';
import css from './styles.module.scss';

interface IFinancialBlock {
  bookingDetails: ICustomerBookingDetails;
}

interface IFinancialRow {
  label: string;
  value: Nullable<number>;
}

const FinancialRow: FC<IFinancialRow> = ({ label, value }) => (
  <div className={css.item}>
    <div className={css.label}>{label}</div>
    <div className={css.value}>{formatToMoneyString(value)}</div>
  </div>
);

const FinancialBlock: FC<IFinancialBlock> = ({ bookingDetails }) => {
  const { invoice, statement } = bookingDetails;

  const rows = [
    { label: 'Parts cost', value: bookingDetails.parts_cost },
    { label: 'Labour cost', value: bookingDetails.labour_cost },
    { label: 'Total jobcard cost', value: bookingDetails.jobcard?.cost_total || null },
    ...(invoice ? [{ label: 'Total invoice cost', value: invoice.grand_total }] : []),
    ...(statement ? [{ label: 'Total statement cost', value: statement.grand_total }] : []),
  ];

  const finalInvoice = invoice ? invoice.pdf : statement ? statement.pdf : null;

  return (
    <Box
      title='Financial'
      icon={<StatementIcon />}
      buttons={
        finalInvoice ? (
          <Button text='Download final invoice' iconR={<DocDownloadIcon />} variant='text' link={finalInvoice} />
        ) : null
      }
    >
      <div className={css.content}>
        {rows.map(({ label, value }, idx) => (
          <FinancialRow label={label} value={value} key={idx} />
        ))}
      </div>
    </Box>
  );
};

export default FinancialBlock;
