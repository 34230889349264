import React, { FC } from 'react';
import { Button, Modal } from '@components';

interface ISuccessModal {
  closeModal: () => void;
}

const SuccessModal: FC<ISuccessModal> = ({ closeModal }) => {
  return (
    <Modal title='Thank you!' closeModal={closeModal} loading={false}>
      <Modal.Content>We will take care of your feedback and use it to improve Workshop Services.</Modal.Content>
      <Modal.Footer>
        <Button text='Ok' onClick={closeModal} />
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
