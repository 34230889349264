import React, { FC } from 'react';
import { IBookingStatus, IDropdownOption } from '@common/interfaces';
import css from './styles.module.scss';

interface ICustomerStatusDropdown {
  status?: string;
}

interface StatusOption extends IDropdownOption {
  color?: string;
  backgroundColor?: string;
}

const BASE_STATUSES = [
  // TO PROCESS
  {
    label: 'Submitted',
    value: IBookingStatus.NOT_PROCESSED,
    backgroundColor: '#c00000',
  },
  {
    label: 'Accepted by workshop',
    value: IBookingStatus.ACCEPTED,
    backgroundColor: '#5597d3',
  },
  {
    label: 'Check-in date confirmed',
    value: IBookingStatus.CHECK_IN_CONFIRMED_BY_CLIENT,
    backgroundColor: '#2e75b5',
  },
  {
    label: 'On hold',
    value: IBookingStatus.ON_HOLD,
    backgroundColor: '#C37A6B',
  },
  {
    label: 'Check-in date to confirm',
    value: IBookingStatus.CHECK_IN_SCHEDULED,
    backgroundColor: '#5597d3',
  },
  {
    label: 'New check-in date requested',
    value: IBookingStatus.NEW_CHECK_IN_DATE_REQUESTED,
    backgroundColor: '#2e75b5',
  },

  // IN PROGRESS
  {
    label: 'Asset under inspection',
    value: IBookingStatus.INSPECTED,
    backgroundColor: '#1e4e79',
  },
  {
    label: 'Processing',
    value: IBookingStatus.PFI_IN_APPROVAL,
    backgroundColor: '#1e4e79',
  },
  {
    label: 'PFI to approve',
    value: IBookingStatus.PFI_SENT,
    backgroundColor: '#b88c00',
  },
  {
    label: 'PFI approved',
    value: IBookingStatus.SIGNED_PFI_RECEIVED,
    backgroundColor: '#7f6000',
  },
  {
    label: 'Work in progress',
    value: IBookingStatus.WORK_IN_PROGRESS,
    backgroundColor: '#c55a11',
  },

  // CLOSED
  {
    label: 'Rejected',
    value: IBookingStatus.REJECTED,
    backgroundColor: '#757070',
  },
  {
    label: 'Work completed',
    value: IBookingStatus.WORK_COMPLETED,
    backgroundColor: '#6aa343',
  },
  {
    label: 'Ready for pick up',
    value: IBookingStatus.READY_FOR_PICK_UP,
    backgroundColor: '#548135',
  },
  {
    label: 'Asset collected',
    value: IBookingStatus.HANDOVER_DONE,
    backgroundColor: '#385623',
  },
  {
    label: 'Invoice received',
    value: IBookingStatus.INVOICE_SENT,
    backgroundColor: '#bf5a80',
  },
  {
    label: 'Payment sent',
    value: IBookingStatus.INVOICE_PAID,
    backgroundColor: '#921040',
  },
  {
    label: 'Completed',
    value: IBookingStatus.COMPLETED,
    backgroundColor: '#5d0021',
  },
  {
    label: 'Cancelled by workshop',
    value: IBookingStatus.CANCELLED,
    backgroundColor: '#3B3838',
  },
] as StatusOption[];

const CustomerStatusDropdown: FC<ICustomerStatusDropdown> = ({ status }) => {
  const value = BASE_STATUSES.find(({ value }) => value === status);

  if (!value) {
    return <div className={css.status}>No status</div>;
  } else {
    return (
      <div className={css.status} style={{ backgroundColor: value.backgroundColor, color: value.color }}>
        {value.label}
      </div>
    );
  }
};

export default CustomerStatusDropdown;
