import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip, TooltipItem } from 'chart.js';
import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Nullable, ValueItem } from '@common/interfaces';
import css from './styles.module.scss';

// Note: customized only for two datasets!

interface DataStacked {
  label: string;
  backgroundColor: string;
  data: ValueItem[];
}

interface IBarChart {
  data: DataStacked[];
  title?: string | JSX.Element;
  isHorizontal?: boolean;
  height?: number;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, {
  id: 'paddingBelowLegends',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeInit: function (chart: any) {
    const originalFit = chart.legend.fit;
    if (chart.config._config.type === 'bar') {
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 15;
      };
    }
  },
});

const fixValue = (value: Nullable<number>) => {
  if (value === null || value < 0) return 0;
  return value;
};

const BarChart: FC<IBarChart> = ({ data, height, isHorizontal = true, title }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indexAxis: (isHorizontal ? 'y' : 'x') as any,
    responsive: true,
    maintainAspectRatio: height ? false : true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        callbacks: {
          label: (item: TooltipItem<'bar'>) => {
            return `${item.dataset.label} - ${data[item.datasetIndex].data[item.dataIndex].value}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: isHorizontal,
          color: '#dce0e5',
          borderWidth: 0,
          lineWidth: 1,
        },
        ticks: {
          display: isHorizontal,
          color: '#1d1f20',
          padding: 10,
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
      y: {
        grid: {
          display: !isHorizontal,
          color: '#dce0e5',
          borderWidth: 0,
          lineWidth: 1,
        },
        ticks: {
          color: '#1d1f20',
          autoSkip: false,
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
    },
  };

  const calculatedData = {
    labels: data[0].data.map(({ label }) => label),
    datasets: [
      {
        label: data[0].label,
        data: data[0].data.map(({ value }) => fixValue(value)),
        backgroundColor: data[0].backgroundColor,
        hoverBackgroundColor: data[0].backgroundColor,
        barThickness: 28,
        borderWidth: { top: 7, right: 0, bottom: 7, left: 0 },
        borderColor: data[1].backgroundColor,
        hoverBorderColor: data[1].backgroundColor,
      },
      {
        label: data[1].label,
        data: data[1].data.map(({ value }, idx) => {
          const fixedValue = fixValue(value);
          return fixedValue ? fixedValue - (data[0].data[idx].value || 0) : 0;
        }),
        backgroundColor: data[1].backgroundColor,
        hoverBackgroundColor: data[1].backgroundColor,
        barThickness: 28,
      },
    ],
  };

  return (
    <div className={css.wrapper}>
      <div className={css.title}>{title}</div>
      <Bar options={options} data={calculatedData} />
      <div className={css.labels}>
        {data.map(({ label, backgroundColor }, idx) => {
          return (
            <div className={css.label} key={idx}>
              <div className={css.dot} style={{ backgroundColor }}></div>
              <div className={css.text}>{label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BarChart;
