import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import {
  AppointmentListParams,
  IAppointmentRepository,
  DefaultListParams,
  IDraftAppointment,
} from '@common/interfaces';
import { decoupledClientInstance } from '@common/utils/axios';

class AppointmentRepository extends AxiosRepository implements IAppointmentRepository {
  public constructor(protected authClient: AxiosInstance) {
    super((decoupledClientInstance as any).create(), authClient);
  }

  async getAppointments(facilityId: string, params?: AppointmentListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/appointments/`, { params });
    return data;
  }

  async getAppointmentsForToday(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/appointments/for-today/`);
    return data;
  }

  async createAppointment(facilityId: string, appointment: IDraftAppointment) {
    const { data } = await this.post(`/facilities/${facilityId}/appointments/`, appointment);
    return data;
  }

  async updateAppointment(facilityId: string, appointmentId: string, appointment: Partial<IDraftAppointment>) {
    const { data } = await this.patch(`/facilities/${facilityId}/appointments/${appointmentId}/`, appointment);
    return data;
  }

  async getMechanics(facilityId: string, params?: DefaultListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/appointments/mechanics/`, {
      params,
    });
    return data;
  }
}

export default AppointmentRepository;
