import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, TextArea, Tip } from '@components';
import { useRepository } from '@context';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IConfirmDefectsData, IDefects, KnownIssue } from '@common/interfaces';
import { CloseRoundIcon, EditIcon, SuccessIcon } from '@assets/svg';
import css from './styles.module.scss';

interface IApproveDefectsModal {
  bookingId: string;
  closeModal: () => void;
  refetchDefects: () => void;
  defects: IDefects;
}

const ApproveDefectsModal: FC<IApproveDefectsModal> = ({ defects, refetchDefects, bookingId, closeModal }) => {
  const { customerRepository } = useRepository();

  const [items, setItems] = useState<KnownIssue[]>([]);
  const [remarks, setRemarks] = useState('');

  const { mutate: confirmBookingDefects } = useMutation(
    (data: IConfirmDefectsData) => customerRepository.confirmBookingDefects(bookingId, data),
    {
      onSuccess: () => {
        toast.success('Defects were confirmed');
        closeModal();
        refetchDefects();
      },
    }
  );

  const onApprove = (uuid: string) => {
    setItems((prevArray) => [...prevArray].map((item) => (item.uuid === uuid ? { ...item, accepted: true } : item)));
  };

  const onReject = (uuid: string) => {
    setItems((prevArray) => [...prevArray].map((item) => (item.uuid === uuid ? { ...item, accepted: false } : item)));
  };

  const onEdit = (uuid: string) => {
    setItems((prevArray) => [...prevArray].map((item) => (item.uuid === uuid ? { ...item, accepted: null } : item)));
  };

  const onSubmit = () => {
    confirmBookingDefects({
      defects_rejection_remarks: remarks || undefined,
      known_issues: items,
    });
  };

  useEffect(() => {
    const currentDefects = defects.known_issues.filter(({ accepted }) => accepted !== false); // Note: except rejected defects
    setItems(currentDefects);
  }, [defects]);

  const isInvalid = items.filter(({ from_do }) => from_do).some(({ accepted }) => accepted === null);

  return (
    <Modal className={css.modal} title='Approve new defects' closeModal={closeModal}>
      <Modal.Content>
        <div className={css.subtitle}>Workshop comment</div>
        <div className={css.note}>{defects.new_defects_remarks}</div>
        {items.map(({ accepted, from_do, text, type_of_repair, uuid }, idx) => {
          return (
            <div className={css.defectRow} key={idx}>
              <span>{`${idx + 1}. ${text} / ${type_of_repair}`}</span>
              <div className={css.actions}>
                {!from_do ? (
                  <span className={css.fromCustomer}>Requested by you</span>
                ) : accepted === null ? (
                  <>
                    <Button
                      className={css.buttonReject}
                      iconR={<CloseRoundIcon />}
                      onClick={() => onReject(uuid)}
                      text='Reject'
                      variant='text'
                    />
                    <Button
                      className={css.buttonApprove}
                      iconR={<SuccessIcon />}
                      onClick={() => onApprove(uuid)}
                      text='Approve'
                      variant='text'
                    />
                  </>
                ) : (
                  <>
                    <span className={css.status}>{accepted ? 'Approved' : 'Rejected'}</span>
                    <Button variant='icon' iconR={<EditIcon />} onClick={() => onEdit(uuid)} />
                  </>
                )}
              </div>
            </div>
          );
        })}
        <div className={css.subtitle}>
          Your comments <span>(optional)</span>
        </div>
        <TextArea value={remarks} onChange={setRemarks} />
      </Modal.Content>
      <Modal.Footer>
        <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
        <Tip text='Please approve or reject all items' isVisible={isInvalid} className={css.tip}>
          <Button text='Submit' onClick={onSubmit} disabled={isInvalid} />
        </Tip>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveDefectsModal;
