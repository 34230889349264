import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import {
  IBookingRepository,
  IBookingListParams,
  BookingFormData,
  ICommentDraft,
  IBookingFiltersParams,
  DefaultListParams,
  ModelsListParams,
  IBookingDetailsPostData,
  JobcardCreationData,
  JobcardLinkingData,
  BookingAnalyticsParams,
  BookingAnalyticsOptionsParams,
  IAddDefectsData,
  AssetsListParams,
} from '@common/interfaces';
import { decoupledClientInstance } from '@common/utils/axios';

class BookingRepository extends AxiosRepository implements IBookingRepository {
  public constructor(protected authClient: AxiosInstance) {
    super((decoupledClientInstance as any).create(), authClient);
  }

  async getBookings(facilityId: string, params?: IBookingListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/`, { params });
    return data;
  }

  async getBookingDetails(facilityId: string, bookingId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/`);
    return data;
  }

  async getFilters(facilityId: string, params?: IBookingFiltersParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/filters/`, { params });
    return data;
  }

  async exportBookings(facilityId: string, params?: IBookingListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/export/`, {
      responseType: 'blob',
      params,
    });
    return new File([data], `booking_list.xls`);
  }

  async updateBooking(facilityId: string, bookingId: string, updateData: Partial<IBookingDetailsPostData>) {
    const { data } = await this.patch(`/facilities/${facilityId}/bookings/${bookingId}/`, updateData);
    return data;
  }

  async createBooking(facilityId: string, payload: BookingFormData) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/form/`, payload);
    return data;
  }

  async getMakes(facilityId: string, params?: DefaultListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/makes/`, { params });
    return data;
  }

  async createMake(facilityId: string, label: string) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/makes/`, { label });
    return data;
  }

  async getModels(facilityId: string, params?: ModelsListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/models/`, { params });
    return data;
  }

  async createModel(facilityId: string, label: string, make: string) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/models/`, { label, make });
    return data;
  }

  async getAssets(params?: AssetsListParams) {
    const { data } = await this.get('/assets/', { params });
    return data;
  }

  async getBookingFormOptions(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/options/`);
    return data;
  }

  async createAsset(facilityId: string, bookingId: string) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/${bookingId}/asset-creation-request/`);
    return data;
  }

  async addDefects(facilityId: string, bookingId: string, defects: IAddDefectsData) {
    const { data } = await this.patch(`/facilities/${facilityId}/bookings/${bookingId}/defects/`, defects);
    return data;
  }

  async getDefects(facilityId: string, bookingId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/defects/`);
    return data;
  }

  async createJobcard(facilityId: string, bookingId: string, payload: JobcardCreationData) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/${bookingId}/create-jobcard/`, payload);
    return data;
  }

  async linkJobcard(facilityId: string, bookingId: string, payload: JobcardLinkingData) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/${bookingId}/link-jobcard/`, payload);
    return data;
  }

  async getComments(facilityId: string, bookingId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/comments/`, {
      params: { limit: 300 },
    });
    return data;
  }

  async sendComment(facilityId: string, bookingId: string, comment: ICommentDraft) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/${bookingId}/comments/`, comment);
    return data;
  }

  async getBookingFiles(facilityId: string, bookingId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/documents/`);
    return data;
  }

  async addBookingFile(facilityId: string, bookingId: string, payload: FormData) {
    const { data } = await this.post(`/facilities/${facilityId}/bookings/${bookingId}/documents/`, payload);
    return data;
  }

  async getAnalytics(facilityId: string, params: BookingAnalyticsParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/analytics/`, { params });
    return data;
  }

  async getAnalyticsOptions(facilityId: string, params: BookingAnalyticsOptionsParams) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/analytics/options/`, { params });
    return data;
  }

  async getHomeStats(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/key-numbers/`);
    return data;
  }

  async getBookingSummary(facilityId: string, bookingId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/generate/`, {
      responseType: 'blob',
    });
    return new File([data], 'booking_summary.pdf');
  }
}

export default BookingRepository;
