import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormState, Multistep } from 'informed';
import { AssetInformationStep, DetailsStep, ReviewStep, SideMenu } from './components';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { BookingFormData, BookingFormOptions, IFacility, ITypeOfAsset } from '@common/interfaces';
import { Main } from '@components';
import { FormValues, IYupSchema, yupSchemas, STEPS } from './consts';
import { URLS } from '@common/constants';
import css from './styles.module.scss';
import { useGlobalContext } from '@context';

const convertValuesToSubmit = (values: FormValues) => {
  const {
    details: {
      requestor_email,
      requestor_name,
      requestor_phone_number,
      requestor_surname,
      requesting_agency,
      manager_email,
      manager_name,
      manager_surname,
      collector_email,
      collector_name,
      collector_phone_number,
      collector_surname,
    },
    asset: {
      current_odometer_value,
      known_issues,
      make,
      model,
      has_spare_parts,
      is_quotation_required,
      odometer_metric,
      plate_number,
      type_of_asset,
      year_of_asset,
      job_reason,
    },
    review,
  } = values;

  const data = {
    requestor_details: {
      email: requestor_email,
      name: requestor_name,
      phone_number: requestor_phone_number.replace(/[ -]/g, ''),
      surname: requestor_surname,
    },
    manager_details: {
      email: manager_email,
      name: manager_name,
      surname: manager_surname,
    },
    collector_details: {
      email: collector_email,
      name: collector_name,
      phone_number: collector_phone_number?.replace(/[ -]/g, ''),
      surname: collector_surname,
    },
    asset_details: {
      current_odometer_value: parseFloat(current_odometer_value.replace(',', '')),
      known_issues,
      make: make.value,
      model: model?.value,
      has_spare_parts: has_spare_parts.value === 'yes',
      is_quotation_required: is_quotation_required.value === 'yes',
      odometer_metric: odometer_metric.value,
      plate_number: plate_number.value,
      requesting_agency,
      remarks: review?.remarks,
      type_of_asset: type_of_asset.value as ITypeOfAsset,
      year_of_asset: year_of_asset ? parseInt(year_of_asset) : undefined,
      job_reason: job_reason.value,
    },
  };

  return data;
};

const BookingFormPage: FC = () => {
  // Note: in all cases for Booking Form we use selected on map facility NOT current facility from context
  const { id: facilityId = '' } = useParams<{ id: string }>();
  const { bookingRepository, facilityRepository } = useRepository();
  const navigate = useNavigate();
  const [, setKey] = useState(1);

  const { user } = useGlobalContext();

  const [yupSchema, setYupSchema] = useState<IYupSchema>(yupSchemas[STEPS.DETAILS]);

  const { data: formOptions, isLoading: isOptionsLoading } = useQuery<BookingFormOptions>('form-options', () =>
    bookingRepository.getBookingFormOptions(facilityId)
  );

  const { data: facilityData, isLoading: isFacilityLoading } = useQuery<IFacility>('get-facility', () =>
    facilityRepository.getFacility(facilityId)
  );

  const workshopLabel = facilityData ? `${facilityData.city_name} ${facilityData.name}` : '';
  const title = `Maintenance Request - ${workshopLabel}`;

  const { mutate: createBooking, isLoading: inProgress } = useMutation(
    (data: BookingFormData) => bookingRepository.createBooking(facilityId, data),
    {
      onSuccess: ({ asset_details }) =>
        navigate(URLS.BOOK_MAINTENANCE_SUCCESS, {
          state: { workshop: workshopLabel, number: asset_details.request_number },
        }),
    }
  );

  const onFormChange = () => setKey((prevKey) => prevKey + 1);

  const onFormSubmit = ({ values }: FormState) => {
    const finalValues = convertValuesToSubmit(values as unknown as FormValues);
    createBooking(finalValues);
  };

  const countryCode = facilityData?.country?.iso_code;

  const loading = isOptionsLoading || isFacilityLoading || inProgress;

  return (
    <Main loading={loading}>
      {formOptions && facilityData ? (
        <Form onChange={onFormChange} onSubmit={onFormSubmit} yupSchema={yupSchema}>
          <Multistep>
            <div className={css.container}>
              <div className={css.sidebar}>
                <SideMenu setYupSchema={setYupSchema} />
              </div>
              <div className={css.content}>
                <div className={css.title}>{title}</div>
                <DetailsStep setYupSchema={setYupSchema} user={user} countryCode={countryCode} />
                <AssetInformationStep formOptions={formOptions} setYupSchema={setYupSchema} countryCode={countryCode} />
                <ReviewStep setYupSchema={setYupSchema} />
              </div>
            </div>
          </Multistep>
        </Form>
      ) : null}
    </Main>
  );
};

export default BookingFormPage;
