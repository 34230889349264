import React, { FC, useState } from 'react';
import { ApprovingModal, Button, Dropdown, Modal, RejectionModal } from '@components';
import {
  DataItem,
  IBookingDetails,
  IBookingDetailsPostData,
  IBookingStatus,
  IDropdownOption,
} from '@common/interfaces';
import css from './styles.module.scss';
import { useNavigate } from 'react-router';
import { LINKS } from '@common/constants';
import { CloseRoundIcon } from '@assets/svg';

interface IStatusDropdown {
  allowedTransitions?: DataItem[];
  requestor?: string;
  status?: string;
  updateBooking?: (data: IBookingDetailsPostData) => void;
  bookingDetails?: IBookingDetails;
}

interface StatusOption extends IDropdownOption {
  color?: string;
  backgroundColor?: string;
}

const BASE_STATUSES = [
  // TO PROCESS
  {
    label: 'To be processed',
    value: IBookingStatus.NOT_PROCESSED,
    backgroundColor: '#c00000',
  },
  {
    label: 'Accepted',
    value: IBookingStatus.ACCEPTED,
    backgroundColor: '#5597d3',
  },
  {
    label: 'Check-in confirmed by client',
    value: IBookingStatus.CHECK_IN_CONFIRMED_BY_CLIENT,
    backgroundColor: '#2e75b5',
  },
  {
    label: 'On hold',
    value: IBookingStatus.ON_HOLD,
    backgroundColor: '#C37A6B',
  },
  {
    label: 'Check-in date to confirm',
    value: IBookingStatus.CHECK_IN_SCHEDULED,
    backgroundColor: '#5597d3',
  },
  {
    label: 'New check-in date requested',
    value: IBookingStatus.NEW_CHECK_IN_DATE_REQUESTED,
    backgroundColor: '#2e75b5',
  },

  // IN PROGRESS
  {
    label: 'Inspected',
    value: IBookingStatus.INSPECTED,
    backgroundColor: '#1e4e79',
  },
  {
    label: 'PFI in approval',
    value: IBookingStatus.PFI_IN_APPROVAL,
    backgroundColor: '#ffc000',
    color: '#000',
  },
  {
    label: 'PFI sent',
    value: IBookingStatus.PFI_SENT,
    backgroundColor: '#b88c00',
  },
  {
    label: 'Signed PFI received',
    value: IBookingStatus.SIGNED_PFI_RECEIVED,
    backgroundColor: '#7f6000',
  },
  {
    label: 'Work in progress',
    value: IBookingStatus.WORK_IN_PROGRESS,
    backgroundColor: '#c55a11',
  },

  // CLOSED
  {
    label: 'Rejected',
    value: IBookingStatus.REJECTED,
    backgroundColor: '#757070',
  },
  {
    label: 'Work completed',
    value: IBookingStatus.WORK_COMPLETED,
    backgroundColor: '#6aa343',
  },
  {
    label: 'Ready for pick up',
    value: IBookingStatus.READY_FOR_PICK_UP,
    backgroundColor: '#548135',
  },
  {
    label: 'Handover done',
    value: IBookingStatus.HANDOVER_DONE,
    backgroundColor: '#385623',
  },
  {
    label: 'Invoice sent',
    value: IBookingStatus.INVOICE_SENT,
    backgroundColor: '#bf5a80',
  },
  {
    label: 'Invoice paid',
    value: IBookingStatus.INVOICE_PAID,
    backgroundColor: '#921040',
  },
  {
    label: 'Completed',
    value: IBookingStatus.COMPLETED,
    backgroundColor: '#5d0021',
  },
  {
    label: 'Cancelled by FP',
    value: IBookingStatus.CANCELLED,
    backgroundColor: '#3B3838',
  },
] as StatusOption[];

const StatusDropdown: FC<IStatusDropdown> = ({
  allowedTransitions = [],
  bookingDetails,
  requestor,
  status,
  updateBooking,
}) => {
  const navigate = useNavigate();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showOnHoldModal, setShowOnHoldModal] = useState(false);

  const value = BASE_STATUSES.find(({ value }) => value === status);

  if (allowedTransitions.length === 0) {
    if (!value) {
      return <div className={css.status}>No status</div>;
    } else {
      return (
        <div className={css.status} style={{ backgroundColor: value.backgroundColor, color: value.color }}>
          {value.label}
        </div>
      );
    }
  }

  const filteredStatuses = BASE_STATUSES.filter((status) =>
    allowedTransitions
      // Note: hide NEW_CHECK_IN_DATE_REQUESTED status on FE side, we don't need it
      .filter(({ value }) => value !== IBookingStatus.NEW_CHECK_IN_DATE_REQUESTED)
      .find((transition) => transition.value === status.value)
  );

  return (
    <>
      <Dropdown
        className={css.dropdown}
        value={value}
        options={filteredStatuses}
        isDisabled={filteredStatuses.length === 0}
        variant='status'
        onChange={({ value }) => {
          if (value === IBookingStatus.REJECTED) {
            setShowRejectModal(true);
          } else if (value === IBookingStatus.ACCEPTED) {
            setShowApproveModal(true);
          } else if (value === IBookingStatus.ON_HOLD && bookingDetails && bookingDetails.appointments.length > 0) {
            setShowOnHoldModal(true);
          } else if (
            value === IBookingStatus.READY_FOR_PICK_UP &&
            bookingDetails &&
            bookingDetails.appointments.length < 2
          ) {
            navigate(LINKS.plannerCheckOut(bookingDetails.uuid));
          } else if (value !== status) {
            updateBooking?.({ state: value as IBookingStatus });
          }
        }}
      />

      {showRejectModal && updateBooking && (
        <RejectionModal
          closeModal={() => setShowRejectModal(false)}
          updateBooking={updateBooking}
          requestor={requestor}
        />
      )}

      {showApproveModal && updateBooking && (
        <ApprovingModal closeModal={() => setShowApproveModal(false)} updateBooking={updateBooking} />
      )}

      {showOnHoldModal && updateBooking && (
        <Modal
          className={css.modal}
          title='Confirm changing booking status to On Hold'
          closeModal={() => setShowOnHoldModal(false)}
        >
          <Modal.Content>
            <div className={css.note}>
              The check-in appointment will be deleted, and an email will be sent to the requestor. Are you sure you
              want to change the status to <b>On hold</b>?
            </div>
          </Modal.Content>
          <Modal.Footer>
            <Button text='Cancel' variant='transparent-negative' onClick={() => setShowOnHoldModal(false)} />
            <Button
              text='Confirm'
              iconR={<CloseRoundIcon />}
              variant='kumera'
              onClick={() => {
                setShowOnHoldModal(false);
                updateBooking({ state: IBookingStatus.ON_HOLD });
              }}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default StatusDropdown;
