import React, { FC } from 'react';
import { Button, Dropdown, InformedField, Modal, NumberField, TextField } from '@components';
import { ArrowRightIcon, CarIcon } from '@assets/svg';
import { Form, FormState, useFieldApi, useFormState } from 'informed';
import { useFacilityContext, useRepository } from '@context';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import css from './styles.module.scss';
import {
  BookingFormOptions,
  IBookingDetails,
  IBookingDetailsPostData,
  IDropdownOption,
  ITypeOfAsset,
} from '@common/interfaces';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { MAX_ASSET_YEAR, MIN_ASSET_YEAR } from '@common/constants';
import { mapPlateNumbers } from '@common/utils';
import { IAssetDropdownOption } from '@common/utils/mapPlateNumbers';

interface ICreateAssetModal {
  closeModal: () => void;
  bookingDetails: IBookingDetails;
  refetch: () => void;
}

interface AssetFormValues {
  body_type: IDropdownOption;
  department_wfp: IDropdownOption;
  make: IDropdownOption;
  model: IDropdownOption;
  plate_number: IDropdownOption;
  requesting_agency: string;
  type_of_asset: IDropdownOption;
  year_of_asset: string;
}

const YupDropdownType = () => Yup.object().required('Required');
const YupTextType = () => Yup.string().required('Required');
const YupYearType = () =>
  Yup.number()
    .positive('Must be a positive number')
    .max(MAX_ASSET_YEAR, `Year must be less than or equal to ${MAX_ASSET_YEAR}`)
    .min(MIN_ASSET_YEAR, `Year must be more than ${MIN_ASSET_YEAR}`)
    .nullable();

const yupSchema = Yup.object().shape({
  body_type: YupDropdownType(),
  department_wfp: YupDropdownType(),
  make: YupDropdownType(),
  model: YupDropdownType(),
  plate_number: YupDropdownType(),
  requesting_agency: YupTextType(),
  type_of_asset: YupDropdownType(),
  year_of_asset: YupYearType(),
});

const MakeField = () => {
  const { facilityId } = useFacilityContext();
  const { bookingRepository } = useRepository();
  const { setValue: setModelValue } = useFieldApi('model');
  const { setValue: setMakeValue } = useFieldApi('make');

  const loadMakeOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await bookingRepository.getMakes(facilityId, {
      limit: 10,
      search,
      offset: loadedOptions.length,
    });

    return {
      options: results,
      hasMore: Boolean(next),
    };
  };

  const resetModel = () => setModelValue(undefined);

  const handleCreate = async (value: string) => {
    const option = await bookingRepository.createMake(facilityId, value);
    setMakeValue(option);
  };

  return (
    <Dropdown
      onCreateOption={handleCreate}
      loadOptions={loadMakeOptions}
      name='make'
      isSearchable
      placeholder='Search...'
      isCreatable
      onChange={resetModel}
    />
  );
};

const ModelField = () => {
  const { facilityId } = useFacilityContext();
  const { bookingRepository } = useRepository();
  const { setValue: setModelValue } = useFieldApi('model');
  const formState = useFormState();
  const make = (formState.values as unknown as AssetFormValues).make?.value;

  const loadModelOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await bookingRepository.getModels(facilityId, {
      limit: 10,
      search,
      offset: loadedOptions.length,
      make,
    });

    return {
      options: results,
      hasMore: Boolean(next),
    };
  };

  const handleCreate = async (value: string) => {
    const option = await bookingRepository.createModel(facilityId, value, make);
    setModelValue(option);
  };

  return (
    <Dropdown
      onCreateOption={handleCreate}
      loadOptions={loadModelOptions}
      name='model'
      isSearchable
      placeholder='Search...'
      isCreatable
      noOptionsMessage={() => 'Write the model name'}
    />
  );
};

const PlateNumberField = () => {
  const { bookingRepository } = useRepository();
  const { setValue: setMakeValue } = useFieldApi('make');
  const { setValue: setModelValue } = useFieldApi('model');
  const { setValue: setYearValue } = useFieldApi('year_of_asset');

  const loadAssetOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await bookingRepository.getAssets({
      limit: 10,
      search,
      offset: loadedOptions.length,
    });

    return {
      options: mapPlateNumbers(results),
      hasMore: Boolean(next),
    };
  };

  const handleSearchPlateNumber = async ({ make, model, year }: IAssetDropdownOption) => {
    setMakeValue(make);
    setModelValue(model);
    setYearValue(year);
  };

  return (
    <Dropdown
      loadOptions={loadAssetOptions}
      name='plate_number'
      isSearchable
      isCreatable
      placeholder='Search...'
      onChange={handleSearchPlateNumber}
    />
  );
};

const CreateAssetModal: FC<ICreateAssetModal> = ({ bookingDetails, closeModal, refetch }) => {
  const { facilityId } = useFacilityContext();
  const { bookingRepository } = useRepository();

  const {
    body_type,
    department_wfp,
    make,
    model,
    plate_number,
    requesting_agency,
    type_of_asset,
    uuid: bookingId,
    year_of_asset,
  } = bookingDetails;

  const { data: formOptions, isLoading: isOptionsLoading } = useQuery<BookingFormOptions>('form-options', () =>
    bookingRepository.getBookingFormOptions(facilityId)
  );

  const { mutate: createAsset, isLoading: inCreationAssetProgress } = useMutation(
    () => bookingRepository.createAsset(facilityId, bookingId),
    {
      onSuccess: () => {
        refetch();
        toast.success('Asset creation request was sent');
        closeModal();
      },
    }
  );

  const { mutate: updateBooking, isLoading: inUpdatingBookingProgress } = useMutation(
    (data: IBookingDetailsPostData) => bookingRepository.updateBooking(facilityId, bookingId, data),
    {
      onSuccess: () => createAsset(),
    }
  );

  const onSubmit = ({ values }: FormState) => {
    const { body_type, department_wfp, make, model, plate_number, requesting_agency, type_of_asset, year_of_asset } =
      values as unknown as AssetFormValues;

    const formData = {
      body_type: body_type.value,
      department_wfp: department_wfp.value,
      make: make.value,
      model: model.value,
      plate_number: plate_number.value,
      requesting_agency,
      type_of_asset: type_of_asset.value as ITypeOfAsset,
      year_of_asset: parseFloat(year_of_asset),
    };

    updateBooking(formData);
  };

  const initialValues = {
    body_type: formOptions?.body_types.find(({ value }) => value === body_type?.uuid),
    department_wfp: formOptions?.wfp_departments.find(({ value }) => value === department_wfp),
    make,
    model,
    plate_number: { label: plate_number, value: plate_number },
    requesting_agency,
    type_of_asset: formOptions?.type_of_asset.find(({ value }) => value === type_of_asset),
    year_of_asset,
  };

  const loading = isOptionsLoading || inUpdatingBookingProgress || inCreationAssetProgress;

  return (
    <Modal className={css.modal} title='Request creation of asset' closeModal={closeModal} loading={loading}>
      {formOptions ? (
        <Form onSubmit={onSubmit} yupSchema={yupSchema} initialValues={initialValues}>
          <Modal.Content>
            <div className={css.note}>
              The asset <b>{plate_number}</b> cannot be found on FMS. Please enter the missing data to request the
              creation to the FMS support team.
            </div>
            <div className={css.subtitle}>
              <CarIcon /> Asset details
            </div>
            <div className={css.row}>
              <InformedField label='Plate number *'>
                <PlateNumberField />
              </InformedField>
              <InformedField label='Asset type *'>
                <Dropdown name='type_of_asset' options={formOptions.type_of_asset} />
              </InformedField>
            </div>
            <div className={css.row}>
              <InformedField label='WFP Department *'>
                <Dropdown name='department_wfp' options={formOptions.wfp_departments} />
              </InformedField>
              <InformedField label='Make code *'>
                <MakeField />
              </InformedField>
            </div>
            <div className={css.row}>
              <InformedField label='Customer *'>
                <TextField name='requesting_agency' disabled />
              </InformedField>
              <InformedField label='Model *'>
                <ModelField />
              </InformedField>
            </div>
            <div className={css.row}>
              <InformedField label='Body type *'>
                <Dropdown name='body_type' options={formOptions.body_types} />
              </InformedField>
              <InformedField label='Manufacture year *'>
                <NumberField name='year_of_asset' skipFormatting />
              </InformedField>
            </div>
            <div className={css.note}>
              The request will be sent to the FMS support team, you can track it and proceed with the booking processing
              from the customer care area on the Global Fleet Portal
            </div>
          </Modal.Content>
          <Modal.Footer>
            <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
            <Button text='Submit request' iconR={<ArrowRightIcon />} type='submit' />
          </Modal.Footer>
        </Form>
      ) : null}
    </Modal>
  );
};

export default CreateAssetModal;
