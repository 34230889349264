import { FC, useState } from 'react';
import { IBookingDetails, IBookingFile, PaginatedResponse, ProFormaInvoice } from '@common/interfaces';
import { DocDownloadIcon, DocIcon, DocMoneyIcon, DocSuccessIcon, DocUploadIcon } from '@assets/svg';
import { Button, Empty, Loader } from '@components';
import { useFacilityContext, useRepository } from '@context';
import { useQuery } from 'react-query';
import { DEFAULT_USER_DATE_FORMAT } from '@common/constants';
import { format } from 'date-fns';
import UploadFileModal from '../UploadFileModal';
import Box from '../Box';
import css from './styles.module.scss';

interface IDocsBlock {
  bookingDetails: IBookingDetails;
}

interface IDocumentItem {
  created: string;
  file_url: string;
  icon: JSX.Element;
  name: string;
}

const DocumentItem: FC<IDocumentItem> = ({ name, created, file_url, icon }) => {
  return (
    <div className={css.item}>
      {icon}
      <div className={css.info}>
        {name}
        <div className={css.date}>{format(created, DEFAULT_USER_DATE_FORMAT)}</div>
      </div>
      <Button
        className={css.downloadButton}
        text='Download'
        iconR={<DocDownloadIcon />}
        link={file_url}
        variant='text'
        target='_blank'
      />
    </div>
  );
};

const DocsBlock: FC<IDocsBlock> = ({ bookingDetails }) => {
  const { facilityId } = useFacilityContext();
  const { pfiRepository, bookingRepository } = useRepository();
  const { pfi: pfiId, uuid: bookingId } = bookingDetails;

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);

  const { data: pfiData, isLoading: isPfiLoading } = useQuery<ProFormaInvoice>(
    `pfi-${pfiId}`,
    () => pfiRepository.getPfi(facilityId, pfiId || ''),
    {
      enabled: Boolean(pfiId),
    }
  );

  const {
    data: files,
    isLoading: isFilesLoading,
    refetch,
  } = useQuery<PaginatedResponse<IBookingFile>>(`files-${bookingId}`, () =>
    bookingRepository.getBookingFiles(facilityId, bookingId)
  );

  const data = [
    ...(pfiData?.pdf
      ? [
          {
            file_url: pfiData.pdf,
            created: pfiData.updated,
            name: 'Pro-forma invoice',
            icon: <DocMoneyIcon className={css.iconDocMoney} />,
          },
        ]
      : []),
    ...(pfiData?.signed_pdf
      ? [
          {
            file_url: pfiData.signed_pdf,
            created: pfiData.updated,
            name: 'Signed pro-forma invoice',
            icon: <DocMoneyIcon className={css.iconDocMoney} />,
          },
        ]
      : []),
    ...(files?.results || []).map(({ file_url, created }) => ({
      file_url,
      created,
      name: file_url.split('booking_documents/')[1]?.split('?')[0] || 'File',
      icon: <DocIcon className={css.iconDoc} />,
    })),
  ];

  const loading = isPfiLoading || isFilesLoading;

  return (
    <Box
      title='Documents'
      icon={<DocSuccessIcon />}
      buttons={
        <Button
          text='Upload document'
          iconR={<DocUploadIcon />}
          variant='text'
          onClick={() => setShowUploadFileModal(true)}
        />
      }
    >
      <div className={css.container}>
        <Loader loading={loading}>
          {!data.length && <Empty text='No documents related with current booking' />}
          {data.map((props, idx) => (
            <DocumentItem {...props} key={idx} />
          ))}
        </Loader>
      </div>
      {showUploadFileModal && (
        <UploadFileModal
          note='Upload documents'
          bookingId={bookingId}
          closeModal={() => setShowUploadFileModal(false)}
          refetch={refetch}
        />
      )}
    </Box>
  );
};

export default DocsBlock;
