import { FC, useState } from 'react';
import { ApprovingModal, Button, RejectionModal, Tip } from '@components';
import {
  DocAddIcon,
  CloseRoundIcon,
  SuccessIcon,
  WrenchCarIcon,
  DocSuccessIcon,
  CalendarIcon,
  WrenchPlannerIcon,
  WrenchScrewIcon,
} from '@assets/svg';
import { ButtonVariant } from '@components/Button';
import {
  IAssetStatusValue,
  IBookingDetails,
  IBookingDetailsPostData,
  IBookingStatus,
  IDefects,
  Nullable,
} from '@common/interfaces';
import { useFacilityContext, useGlobalContext, useRepository } from '@context';
import { useNavigate } from 'react-router';
import UploadFileModal from '../UploadFileModal';
import CreateAssetModal from '../CreateAssetModal';
import SelectFMSCustomerModal from '../SelectFMSCustomerModal';
import { LINKS } from '@common/constants';
import AddDefectsModal from '../AddDefectsModal';
import SendPfiModal from '../SendPfiModal';
import css from './styles.module.scss';

interface IActionsBlock {
  bookingDetails: IBookingDetails;
  defects?: IDefects;
  refetch: () => void;
  refetchDefects: () => void;
  sendPfiDocument: () => void;
  updateBooking: (data: IBookingDetailsPostData) => void;
}

interface Action {
  disabled?: boolean;
  icon?: JSX.Element;
  isVisible: boolean;
  key: string;
  link?: string;
  onClick?: Nullable<() => void>;
  text?: string;
  tip?: string;
  variant?: ButtonVariant;
}

const ActionsBlock: FC<IActionsBlock> = ({
  bookingDetails,
  defects,
  refetch,
  refetchDefects,
  sendPfiDocument,
  updateBooking,
}) => {
  const { facilityId } = useFacilityContext();
  const { waffles } = useGlobalContext();
  const navigate = useNavigate();
  const { pfiRepository } = useRepository();

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showUploadPFIModal, setShowUploadPFIModal] = useState(false);
  const [showCreateAssetModal, setShowCreateAssetModal] = useState(false);
  const [showSelectFMSCustomerModal, setShowSelectFMSCustomerModal] = useState(false);
  const [showAddDefectsModal, setShowAddDefectsModal] = useState(false);
  const [showSendPfiModal, setShowSendPfiModal] = useState(false);

  const {
    state,
    asset_status,
    uuid,
    is_quotation_required,
    pfi,
    jobcard,
    fms_request_sent,
    request_number,
    type_of_asset,
    requestor_details: { name, surname },
  } = bookingDetails;

  const isJobcardCreationAvailable =
    !jobcard &&
    (state === IBookingStatus.INSPECTED ||
      state === IBookingStatus.PFI_IN_APPROVAL ||
      state === IBookingStatus.SIGNED_PFI_RECEIVED ||
      state === IBookingStatus.PFI_SENT);

  const isPFIGenerationAvailable =
    is_quotation_required && !pfi && (state === IBookingStatus.INSPECTED || state === IBookingStatus.WORK_IN_PROGRESS);

  const isJobcardCreationInProgress = Boolean(fms_request_sent && !jobcard);

  const isAssetCreationInProgress = asset_status.value === IAssetStatusValue.CREATION_ONGOING;

  const isCustomerAreaEnabled = waffles.flags.CUSTOMER_AREA.is_active;

  const shouldDefectsBeHandled = defects?.known_issues.some(({ need_to_accept }) => need_to_accept);

  const ACTIONS_LIST: Array<Action> = [
    {
      icon: <DocSuccessIcon />,
      isVisible: !isCustomerAreaEnabled && state === IBookingStatus.PFI_SENT,
      key: 'confirm-pfi',
      onClick: () => setShowUploadPFIModal(true),
      text: 'Confirm PFI approval',
      variant: 'space' as ButtonVariant,
    },
    {
      icon: <WrenchScrewIcon />,
      isVisible: isCustomerAreaEnabled && state === IBookingStatus.INSPECTED,
      key: 'add-new-defects',
      text: 'Add new defects',
      variant: 'kumera' as ButtonVariant,
      onClick: () => setShowAddDefectsModal(true),
    },
    {
      icon: <DocAddIcon />,
      isVisible: isPFIGenerationAvailable,
      key: 'generate-pfi',
      onClick: () => {
        if (!pfi) {
          pfiRepository.createPfi(facilityId, uuid).then((data) => navigate(LINKS.pfi(uuid, data.uuid)));
        } else {
          navigate(LINKS.pfi(uuid, pfi));
        }
      },
      text: 'Generate PFI',
      variant: 'space' as ButtonVariant,
    },
    {
      icon: <DocSuccessIcon />,
      isVisible: isCustomerAreaEnabled && state === IBookingStatus.PFI_IN_APPROVAL,
      key: 'send-pfi',
      onClick: () => setShowSendPfiModal(true),
      text: 'Send PFI to customer',
      variant: 'supernova' as ButtonVariant,
    },
    {
      icon: <CloseRoundIcon />,
      isVisible: state === IBookingStatus.NOT_PROCESSED || state === IBookingStatus.ON_HOLD,
      key: 'reject',
      onClick: () => setShowRejectModal(true),
      text: 'Reject',
      variant: 'alizarin' as ButtonVariant,
    },
    {
      icon: <SuccessIcon />,
      isVisible: state === IBookingStatus.NOT_PROCESSED || state === IBookingStatus.ON_HOLD,
      key: 'approve',
      text: 'Approve',
      variant: 'forest' as ButtonVariant,
      onClick: () => setShowApproveModal(true),
    },
    {
      icon: <CalendarIcon />,
      isVisible: state === IBookingStatus.ACCEPTED && bookingDetails.appointments.length === 0,
      key: 'schedule-check-in',
      text: 'Schedule check-in',
      variant: 'lochmara' as ButtonVariant,
      link: LINKS.plannerCheckIn(uuid),
    },
    {
      icon: <WrenchPlannerIcon />,
      isVisible:
        isCustomerAreaEnabled &&
        state === IBookingStatus.CHECK_IN_SCHEDULED &&
        Boolean(bookingDetails.appointments[0]?.proposed_check_in_dates),
      key: 'customer-check-in',
      text: 'View customer check-in dates',
      variant: 'lochmara' as ButtonVariant,
      link: LINKS.plannerCheckIn(uuid),
    },
    {
      icon: <WrenchCarIcon />,
      isVisible: isJobcardCreationAvailable,
      key: 'create-jobcard',
      text: 'Create jobcard',
      disabled: isAssetCreationInProgress || isJobcardCreationInProgress || shouldDefectsBeHandled,
      onClick:
        asset_status.value === IAssetStatusValue.EXIST_ON_FMS
          ? () => setShowSelectFMSCustomerModal(true)
          : asset_status.value === IAssetStatusValue.NOT_EXIST_ON_FMS
          ? () => setShowCreateAssetModal(true)
          : null,
      variant: 'lochmara' as ButtonVariant,
      tip: shouldDefectsBeHandled
        ? 'Customer needs to approve/reject new defects'
        : isJobcardCreationInProgress
        ? 'Creation in progress, come back later'
        : isAssetCreationInProgress
        ? 'Asset creation ongoing'
        : undefined,
    },
    {
      icon: <CalendarIcon />,
      isVisible: state === IBookingStatus.WORK_COMPLETED,
      key: 'schedule-check-out',
      text: 'Schedule check-out',
      variant: 'lochmara' as ButtonVariant,
      link: LINKS.plannerCheckOut(uuid),
    },
  ].filter((button) => button.isVisible);

  return (
    <div className={css.container}>
      {ACTIONS_LIST.map(({ disabled, icon, key, link, onClick, text, tip, variant }) => {
        return (
          <Tip text={tip || ''} key={key} isVisible={Boolean(tip)}>
            <Button
              className={css.button}
              onClick={onClick || undefined}
              text={text}
              link={link}
              variant={variant}
              disabled={disabled}
              iconR={icon}
            />
          </Tip>
        );
      })}

      {showRejectModal && (
        <RejectionModal
          closeModal={() => setShowRejectModal(false)}
          requestor={`${name} ${surname}`}
          updateBooking={updateBooking}
        />
      )}

      {showApproveModal && (
        <ApprovingModal closeModal={() => setShowApproveModal(false)} updateBooking={updateBooking} />
      )}

      {showUploadPFIModal && (
        <UploadFileModal
          closeModal={() => setShowUploadPFIModal(false)}
          refetch={refetch}
          pfiId={pfi}
          note={`Upload PFI for booking ${request_number}`}
        />
      )}

      {showCreateAssetModal && (
        <CreateAssetModal
          closeModal={() => setShowCreateAssetModal(false)}
          bookingDetails={bookingDetails}
          refetch={refetch}
        />
      )}

      {showSelectFMSCustomerModal && (
        <SelectFMSCustomerModal
          closeModal={() => setShowSelectFMSCustomerModal(false)}
          bookingDetails={bookingDetails}
          refetch={refetch}
        />
      )}

      {showAddDefectsModal && defects && (
        <AddDefectsModal
          defects={defects}
          refetchDefects={refetchDefects}
          bookingId={uuid}
          assetType={type_of_asset}
          closeModal={() => setShowAddDefectsModal(false)}
        />
      )}

      {showSendPfiModal && (
        <SendPfiModal sendPfiDocument={sendPfiDocument} closeModal={() => setShowSendPfiModal(false)} />
      )}
    </div>
  );
};

export default ActionsBlock;
